import {DateListType, MonthsType} from '../constants/types';

export const getYearsRange = (
  end: number,
  start: number,
  step: number
): DateListType => {
  if (start === end || start > end)
    return {[end]: end.toString() as MonthsType};
  return {
    [end]: end.toString() as MonthsType,
    ...getYearsRange(end - step, start, step),
  };
};
