import React from 'react';
import {Theme} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  modalWindowWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.common.white,
    boxShadow:
      '0px 11px 15px -7px rgba(0, 0, 0, 0.16), 0px 24px 38px 3px rgba(0, 0, 0, 0.1), 0px 9px 46px 8px rgba(0, 0, 0, 0.08)',
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    width: theme.spacing(70),
  },
}));

type Props = {
  children: React.ReactElement;
  open: boolean;
  onClose?: () => void;
};

function BaseModalWindow({
  children,
  open,
  onClose,
}: Readonly<Props>): JSX.Element {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      onClose={onClose}
    >
      <Fade in={open}>
        <div className={classes.modalWindowWrapper}>{children}</div>
      </Fade>
    </Modal>
  );
}

export default BaseModalWindow;
