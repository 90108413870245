import React from 'react';
import {SvgIconProps} from '@mui/material';

export function AllClearIDLogo(props: SvgIconProps): JSX.Element {
  return (
    <svg
      width="84"
      height="20"
      viewBox="0 0 84 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2215_5908)">
        <path
          d="M66.5679 2.52795V15.366H62.4092V2.52795H66.5679Z"
          fill="#0054A6"
        />
        <path
          d="M81.0043 8.91168C81.0043 12.3861 79.2207 15.3484 75.4505 15.3484H68.5547V2.47498H73.8347C78.4349 2.47498 81.0087 3.98482 81.0087 8.91168H81.0043ZM77.6932 7.84773H75.4285V5.58296H73.2961V7.85214H71.027V9.98447H73.2961V12.2492H75.4285V9.98447H77.6932V7.85214V7.84773Z"
          fill="#0054A6"
        />
        <path
          d="M15.0484 15.3528H13.0088V2.52356H15.0484V15.3528Z"
          fill="#0054A6"
        />
        <path
          d="M19.0836 15.3527H17.0439V2.52344H19.0836V15.3527Z"
          fill="#0054A6"
        />
        <path
          d="M26.5134 4.25853C25.3215 4.25853 24.3811 4.68235 23.7012 5.52557C23.017 6.36878 22.677 7.53869 22.677 9.02646C22.677 10.5142 23.0037 11.7636 23.6615 12.5583C24.3193 13.3529 25.2685 13.7547 26.5134 13.7547C27.052 13.7547 27.573 13.7017 28.0763 13.5957C28.5795 13.4898 29.1049 13.3529 29.6479 13.1807V14.9599C28.6502 15.3351 27.5244 15.525 26.2618 15.525C24.4032 15.525 22.9772 14.9643 21.9795 13.8341C20.9818 12.7084 20.4873 11.097 20.4873 9.00439C20.4873 7.68438 20.7301 6.52772 21.2113 5.53881C21.6925 4.54991 22.3945 3.79057 23.3083 3.26522C24.2222 2.73986 25.2994 2.47498 26.5311 2.47498C27.829 2.47498 29.0254 2.74869 30.1247 3.2917L29.3786 5.01787C28.9504 4.81479 28.4957 4.6382 28.0189 4.48369C27.5421 4.32917 27.0388 4.25412 26.5134 4.25412V4.25853Z"
          fill="#0054A6"
        />
        <path
          d="M33.3521 15.3528H31.3125V2.52356H33.3521V15.3528Z"
          fill="#0054A6"
        />
        <path
          d="M39.2899 15.5249C37.7977 15.5249 36.6278 15.0879 35.789 14.2182C34.9502 13.3485 34.5264 12.1477 34.5264 10.6202C34.5264 9.09265 34.9149 7.8212 35.6963 6.9206C36.4777 6.01999 37.5505 5.5741 38.919 5.5741C40.1861 5.5741 41.1882 5.95818 41.9211 6.73076C42.6539 7.49893 43.0248 8.55847 43.0248 9.90938V11.0131H36.6278C36.6543 11.9446 36.9103 12.6598 37.3827 13.1631C37.8551 13.6619 38.5261 13.9136 39.387 13.9136C39.9521 13.9136 40.4819 13.8606 40.9719 13.7546C41.4619 13.6487 41.9873 13.4677 42.548 13.2204V14.8804C42.0491 15.1188 41.5458 15.2865 41.0381 15.3837C40.5304 15.4808 39.9477 15.5294 39.2943 15.5294L39.2899 15.5249ZM38.9146 7.11926C38.2657 7.11926 37.7491 7.32675 37.3562 7.73732C36.9677 8.1479 36.7337 8.7483 36.6587 9.53413H41.016C41.0028 8.73947 40.813 8.13907 40.4421 7.73291C40.0713 7.32675 39.5636 7.11926 38.9146 7.11926Z"
          fill="#0054A6"
        />
        <path
          d="M50.5472 15.3528L50.1411 14.0151H50.0704C49.6069 14.5978 49.1433 14.9996 48.6709 15.2071C48.1986 15.4146 47.5982 15.5249 46.8653 15.5249C45.9206 15.5249 45.1833 15.2689 44.6579 14.7612C44.1282 14.2535 43.8633 13.5295 43.8633 12.598C43.8633 11.6091 44.2297 10.863 44.967 10.3597C45.7042 9.8564 46.8212 9.58269 48.3266 9.53413L49.9865 9.48115V8.96904C49.9865 8.35539 49.8453 7.89626 49.5583 7.59164C49.2714 7.28702 48.8255 7.13692 48.2251 7.13692C47.735 7.13692 47.2626 7.20755 46.8123 7.35324C46.362 7.49893 45.925 7.6711 45.51 7.86535L44.8478 6.40848C45.3687 6.13477 45.9382 5.93169 46.5563 5.786C47.1743 5.64473 47.7615 5.5741 48.3089 5.5741C49.5318 5.5741 50.4501 5.83898 51.0726 6.37317C51.695 6.90735 52.0041 7.74174 52.0041 8.88074V15.3484H50.5472V15.3528ZM47.5099 13.9621C48.2515 13.9621 48.8475 13.7546 49.2934 13.3441C49.7437 12.9291 49.9645 12.3507 49.9645 11.6046V10.7703L48.7328 10.8232C47.7703 10.8586 47.0728 11.0175 46.6357 11.3044C46.1987 11.5914 45.9824 12.0285 45.9824 12.62C45.9824 13.0483 46.1104 13.3794 46.362 13.6134C46.6137 13.8473 46.9978 13.9665 47.5099 13.9665V13.9621Z"
          fill="#0054A6"
        />
        <path
          d="M58.5692 5.5741C58.9797 5.5741 59.3197 5.605 59.5846 5.66239L59.3859 7.56515C59.0989 7.49451 58.7943 7.45919 58.4853 7.45919C57.6685 7.45919 57.0063 7.72408 56.5031 8.25826C55.9954 8.79245 55.7437 9.48115 55.7437 10.3332V15.3528H53.7041V5.75069H55.3022L55.5715 7.44595H55.6775C55.9954 6.87203 56.4103 6.41731 56.9225 6.08179C57.4346 5.74627 57.9864 5.57851 58.578 5.57851L58.5692 5.5741Z"
          fill="#0054A6"
        />
        <path
          d="M7.7064 2.52795H5.45047L0.700195 15.3528H2.88991L3.8435 12.6466L4.75294 10.0374H4.74411L5.89194 6.7308C6.19214 5.88758 6.42171 5.10617 6.57623 4.39539C6.63362 4.62496 6.73957 4.99138 6.89409 5.49025C7.0486 5.98912 7.1678 6.35113 7.2561 6.58511L8.44367 10.0419H6.04646L5.42398 11.8298H8.9911L10.2581 15.3528H12.4655L7.7064 2.52795Z"
          fill="#0054A6"
        />
        <path
          d="M80.7095 14.0283C80.7095 13.7943 80.7669 13.5736 80.8861 13.3661C81.0053 13.1586 81.1642 12.9996 81.3717 12.8804C81.5792 12.7612 81.7999 12.7039 82.0383 12.7039C82.2767 12.7039 82.4931 12.7612 82.7005 12.8804C82.908 12.9996 83.067 13.1586 83.1862 13.3661C83.3054 13.5736 83.3628 13.7943 83.3628 14.0327C83.3628 14.2711 83.3054 14.483 83.1906 14.6861C83.0758 14.8892 82.9169 15.0525 82.7094 15.1761C82.5019 15.2997 82.2767 15.3615 82.0339 15.3615C81.7911 15.3615 81.566 15.2997 81.3585 15.1805C81.151 15.0569 80.992 14.898 80.8773 14.6949C80.7625 14.4918 80.7051 14.2711 80.7051 14.0415L80.7095 14.0283ZM80.9258 14.0283C80.9258 14.2269 80.9744 14.4124 81.0759 14.5845C81.173 14.7567 81.3099 14.8892 81.4821 14.9907C81.6542 15.0922 81.8397 15.1408 82.0339 15.1408C82.2282 15.1408 82.418 15.0922 82.5902 14.9907C82.7624 14.8892 82.8948 14.7567 82.9963 14.5845C83.0935 14.4168 83.142 14.2314 83.142 14.0327C83.142 13.834 83.0935 13.6486 82.9919 13.4764C82.8904 13.3043 82.7579 13.1674 82.5858 13.0703C82.4136 12.9732 82.2282 12.9202 82.0339 12.9202C81.8397 12.9202 81.6542 12.9687 81.4821 13.0659C81.3143 13.163 81.1775 13.2998 81.0759 13.472C80.9744 13.6442 80.9258 13.8296 80.9258 14.0327V14.0283ZM82.5725 13.7148C82.5725 13.9179 82.4842 14.0548 82.3032 14.1254L82.7226 14.8274H82.3518L82.0118 14.2181H81.8529V14.8274H81.5218V13.2336H81.9854C82.1884 13.2336 82.3385 13.2734 82.4357 13.3528C82.5284 13.4323 82.5769 13.5515 82.5769 13.7148H82.5725ZM81.8485 13.9576H81.9721C82.0604 13.9576 82.1266 13.9356 82.1708 13.8958C82.2149 13.8561 82.237 13.7943 82.237 13.7192C82.237 13.6354 82.2149 13.5736 82.1708 13.5427C82.1266 13.5073 82.0604 13.4941 81.9677 13.4941H81.8485V13.9576Z"
          fill="#0054A6"
        />
      </g>
      <defs>
        <clipPath id="clip0_2215_5908">
          <rect
            width="82.6574"
            height="13.05"
            fill="white"
            transform="translate(0.700195 2.47498)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
