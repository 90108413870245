import React from 'react';
import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';

import {ViewerScreenError} from './viewerScreenError';
import {ViewerScreenLoading} from './viewerScreenLoading';
import {ViewerScreenMessage} from './viewerScreenMessage';
import {ViewScreenDICOM} from './viewScreenDICOM';

type Props = {
  filePreviewIsLoading: boolean;
  filePreviewHasError: boolean;
  filePreviewType: string;
  filePreviewData: string;
};

const iframeStyles = {
  width: '100%',
  height: '100%',
  border: 'none',
};

const useStyles = makeStyles((theme: Theme) => ({
  previewWrapper: {
    width: '100%',
    height: ' 100%',
    boxSizing: 'border-box',
    flexGrow: 1,
    backgroundColor: theme.palette.grey['50'],
    padding: theme.spacing(1),
  },
}));

export function FileViewer({
  filePreviewIsLoading,
  filePreviewHasError,
  filePreviewType,
  filePreviewData,
}: Readonly<Props>): JSX.Element {
  const classes = useStyles();

  if (filePreviewIsLoading) {
    return (
      <ViewerScreenLoading
        data-testid="file-viewer-loading-state"
        message="File preview is loading"
      />
    );
  }

  if (filePreviewHasError) {
    return (
      <ViewerScreenError
        data-testid="file-viewer-error-state"
        title="File preview error"
        description="Please try to select another file and return back to this one"
      />
    );
  }

  switch (filePreviewType) {
    case 'CCDA':
      return (
        <div className={classes.previewWrapper} data-testid="file-viewer-ccda">
          <iframe
            title="CCDA preview"
            style={{
              ...iframeStyles,
              pointerEvents: 'none',
            }}
            src={`data:text/html;charset=utf-8,${encodeURIComponent(
              filePreviewData
            )}`}
          />
        </div>
      );
    case 'DICOM':
      return (
        <div className={classes.previewWrapper} data-testid="file-viewer-dicom">
          <ViewScreenDICOM src={filePreviewData} />
        </div>
      );
    case 'PDF':
      return (
        <div className={classes.previewWrapper} data-testid="file-viewer-pdf">
          <iframe
            title="PDF preview"
            style={iframeStyles}
            src={filePreviewData}
          />
        </div>
      );
    case 'JPEG':
    case 'PNG':
      return (
        <div className={classes.previewWrapper} data-testid="file-viewer-img">
          <iframe
            title="Image preview"
            src={filePreviewData}
            style={iframeStyles}
          />
        </div>
      );
    default:
      return (
        <ViewerScreenMessage
          dataTestId="file-viewer-no-preview"
          title="Preview is not supported for this file type"
        />
      );
  }
}
