import {
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
  useField,
} from 'formik';
import {FieldHookConfig} from 'formik/dist/Field';

const shouldShowErrorMessage = (meta: FieldMetaProps<string>): boolean => {
  if (!meta.touched) {
    return false;
  }

  return (
    !!(meta.value && !meta.touched && meta.error) ||
    !!(meta.touched && meta.error)
  );
};

export default function useInputFieldFormik(name: FieldHookConfig<string>): {
  field: FieldInputProps<string>;
  meta: FieldMetaProps<string>;
  helpers: FieldHelperProps<string>;
} {
  const [field, meta, helpers] = useField(name);

  if (!meta.error && shouldShowErrorMessage(meta)) {
    helpers.setError(' ');
  }

  return {field, meta, helpers};
}
