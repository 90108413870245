import React from 'react';
import {Info} from '@mui/icons-material';
import {Tooltip, tooltipClasses, TooltipProps} from '@mui/material';
import {styled} from '@mui/material/styles';

const StyledTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} arrow classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.grey['600'],
    padding: theme.spacing(1.25, 2.5),
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)',
    ...theme.typography.body5,
  },
}));

type Props = {
  description: string | JSX.Element;
  placement?: TooltipProps['placement'];
  testId?: string;
};

function InfoTooltip({
  description,
  placement = 'right',
  testId = 'info-tooltip',
}: Readonly<Props>): JSX.Element {
  return (
    <StyledTooltip
      title={description}
      disableFocusListener
      placement={placement}
      arrow
    >
      <Info color="action" data-testid={testId} />
    </StyledTooltip>
  );
}

export default InfoTooltip;
