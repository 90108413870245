import {FormValues} from './types';

export const requestMedicalRecordsInitialValues: FormValues = {
  patientFirstName: '',
  patientLastName: '',
  patientDateOfBirth: '',
  userFirstName: '',
  userLastName: '',
  userEmail: '',
  userPhoneNumber: '',
  userComment: '',
  isRequestHaveMedicalRecords: 'have',
};
