import React from 'react';
import {SvgIconProps} from '@mui/material';

export function HealthConnectLogoIcon(props: SvgIconProps): JSX.Element {
  return (
    <svg
      width="434"
      height="44"
      viewBox="0 0 434 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2215_4048)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.6 22.0002C43.6 34.0402 33.84 43.8002 21.8 43.8002C9.76 43.8002 0 34.0402 0 22.0002C0 9.96019 9.76 0.200195 21.8 0.200195C33.84 0.200195 43.6 9.96019 43.6 22.0002ZM35.69 17.5502H26.24V8.0902H17.35V17.5502H7.89V26.4502H17.34V35.9102H26.25V26.4502H35.71L35.69 17.5502Z"
          fill="white"
        />
        <path
          d="M425.66 33.78H424.84V29.47H423.37V28.77H427.14V29.47H425.67V33.78H425.66Z"
          fill="white"
        />
        <path
          d="M430.07 33.78L428.62 29.59H428.59C428.63 30.21 428.65 30.79 428.65 31.34V33.78H427.91V28.77H429.07L430.46 32.76H430.48L431.91 28.77H433.07V33.78H432.28V31.3C432.28 31.05 432.28 30.73 432.3 30.33C432.31 29.93 432.32 29.69 432.33 29.6H432.3L430.8 33.78H430.08H430.07Z"
          fill="white"
        />
        <path
          d="M73.4397 33.7805V22.9405H60.5597V33.7805H56.2197V9.02051H60.5597V19.1205H73.4397V9.02051H77.7797V33.7805H73.4397Z"
          fill="white"
        />
        <path
          d="M82.0195 33.7804V13.3604H96.9095V16.8904H86.1095V21.6004H96.7295V25.1304H86.1095V30.2504H96.9095V33.7804H82.0195Z"
          fill="white"
        />
        <path
          d="M111.01 13.3604L119.44 33.7804H114.91L113.35 29.9204H103.81L102.25 33.7804H97.6797L106.14 13.3604H111H111.01ZM108.6 17.4804L105 26.3904H112.17L108.61 17.4804H108.6Z"
          fill="white"
        />
        <path
          d="M121.04 33.7804V13.3604H125.12V30.2504H134.48V33.7804H121.04Z"
          fill="white"
        />
        <path
          d="M138.56 33.7804V16.8904H132.51V13.3604H148.62V16.8904H142.61V33.7804H138.56Z"
          fill="white"
        />
        <path
          d="M165.919 33.7804V25.0604H155.149V33.7804H151.069V13.3604H155.149V21.5304H165.919V13.3604H169.929V33.7804H165.919Z"
          fill="white"
        />
        <path
          d="M182.81 33.7805V9.02051H194.99C199.56 9.02051 202.04 11.8405 202.04 15.3305C202.04 18.4105 200.04 20.5305 197.73 21.0105C200.4 21.4205 202.52 24.0505 202.52 27.0605C202.52 30.9205 200 33.7805 195.32 33.7805H182.81ZM197.58 16.0305C197.58 14.2105 196.32 12.8405 194.13 12.8405H187.15V19.2305H194.13C196.32 19.2305 197.58 17.9305 197.58 16.0405V16.0305ZM198.07 26.5005C198.07 24.6405 196.77 23.0505 194.32 23.0505H187.15V29.9605H194.32C196.66 29.9605 198.07 28.6605 198.07 26.5105V26.5005Z"
          fill="white"
        />
        <path
          d="M216.67 13.3604L225.1 33.7804H220.57L219.01 29.9204H209.47L207.91 33.7804H203.34L211.8 13.3604H216.66H216.67ZM214.25 17.4804L210.65 26.3904H217.82L214.26 17.4804H214.25Z"
          fill="white"
        />
        <path
          d="M241.35 33.7804L230.73 19.6404V33.7804H226.68V13.3604H231.02L241.41 27.1004V13.3604H245.46V33.7804H241.34H241.35Z"
          fill="white"
        />
        <path
          d="M262.4 33.7804L255.35 25.3504L253.68 27.2404V33.7704H249.6V13.3604H253.68V22.6804L261.74 13.3604H266.71L257.99 22.9804L267.35 33.7804H262.41H262.4Z"
          fill="white"
        />
        <path
          d="M275.729 21.4204C275.729 13.8104 281.339 8.61035 288.459 8.61035C292.839 8.61035 295.879 10.6904 297.849 13.5104L295.289 14.9604C293.879 12.8404 291.279 11.3604 288.459 11.3604C283.039 11.3604 278.919 15.4804 278.919 21.4204C278.919 27.3604 283.039 31.4804 288.459 31.4804C291.279 31.4804 293.879 29.9604 295.289 27.8804L297.889 29.2904C295.809 32.1504 292.839 34.2304 288.459 34.2304C281.329 34.2304 275.729 29.0304 275.729 21.4204Z"
          fill="white"
        />
        <path
          d="M299.75 23.5702C299.75 17.5602 303.95 12.9102 310.29 12.9102C316.63 12.9102 320.83 17.5502 320.83 23.5702C320.83 29.5902 316.63 34.2202 310.29 34.2202C303.95 34.2202 299.75 29.5802 299.75 23.5702ZM317.87 23.5702C317.87 18.9302 314.9 15.4802 310.3 15.4802C305.7 15.4802 302.73 18.9302 302.73 23.5702C302.73 28.2102 305.7 31.6602 310.3 31.6602C314.9 31.6602 317.87 28.1702 317.87 23.5702Z"
          fill="white"
        />
        <path
          d="M339.4 33.7804L327.33 17.8904V33.7804H324.43V13.3604H327.47L339.42 28.9504V13.3604H342.32V33.7804H339.39H339.4Z"
          fill="white"
        />
        <path
          d="M361.93 33.7804L349.86 17.8904V33.7804H346.96V13.3604H350L361.95 28.9504V13.3604H364.85V33.7804H361.92H361.93Z"
          fill="white"
        />
        <path
          d="M369.5 33.7804V13.3604H383.68V15.9204H372.43V22.0504H383.49V24.6104H372.43V31.2204H383.68V33.7804H369.5Z"
          fill="white"
        />
        <path
          d="M386.43 23.5302C386.43 17.2602 391.14 12.9102 397.34 12.9102C400.98 12.9102 403.69 14.6202 405.43 17.1102L403.09 18.4102C401.86 16.6302 399.71 15.4802 397.34 15.4802C392.85 15.4802 389.4 18.8202 389.4 23.5402C389.4 28.2602 392.85 31.6702 397.34 31.6702C399.72 31.6702 401.94 30.4802 403.09 28.7402L405.47 30.0402C403.65 32.5302 400.98 34.2402 397.34 34.2402C391.14 34.2402 386.43 29.8602 386.43 23.5502V23.5302Z"
          fill="white"
        />
        <path
          d="M412.68 33.7804V15.9204H406.33V13.3604H421.89V15.9204H415.58V33.7804H412.68Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2215_4048">
          <rect
            width="433.07"
            height="43.6"
            fill="white"
            transform="translate(0 0.200195)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
