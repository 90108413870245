import {useState} from 'react';
import axios from 'axios';
import {useSetRecoilState} from 'recoil';

import {pageTypeState} from '../atoms/pageTypeState';
import {referenceCodeState} from '../atoms/referenceCodeState';
import {LOGIN_PAGE_ATTEMPTS} from '../constants/attempts';
import {ERROR_RESPONSE_MESSAGES, PAGE_TYPE} from '../constants/types';
import {URLS} from '../constants/urls';

import useSnackbar from './useSnackbar';

type Return = {
  errorMessage: string | null;
  setErrorMessage: (value: string | null) => void;
  remainingAttempts: number;
  authorizeReferenceCode: (referenceCode: string) => Promise<void>;
  isAuthorizing: boolean;
};
const useReferenceCode = (): Return => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [attempts, setAttempts] = useState(LOGIN_PAGE_ATTEMPTS);
  const showMessage = useSnackbar();
  const setPage = useSetRecoilState(pageTypeState);
  const setReferenceCode = useSetRecoilState(referenceCodeState);

  const decreaseAttempts = (errorMessage: ERROR_RESPONSE_MESSAGES): void => {
    if (
      errorMessage === ERROR_RESPONSE_MESSAGES.WRONG_AUTH_CODE ||
      errorMessage === ERROR_RESPONSE_MESSAGES.REQUEST_COMPLETED ||
      errorMessage === ERROR_RESPONSE_MESSAGES.REQUEST_OBSOLETE ||
      errorMessage === ERROR_RESPONSE_MESSAGES.REQUEST_EXPIRED
    ) {
      setAttempts(prevState => prevState - 1);
    } else if (
      errorMessage === ERROR_RESPONSE_MESSAGES.ATTEMPTS_LIMIT_REACHED
    ) {
      setAttempts(0);
    }
  };

  const handleErrorMessage = (errorStatus: ERROR_RESPONSE_MESSAGES): void => {
    switch (errorStatus) {
      case ERROR_RESPONSE_MESSAGES.WRONG_AUTH_CODE:
        setErrorMessage('Invalid reference code');
        break;
      case ERROR_RESPONSE_MESSAGES.REQUEST_COMPLETED:
        setErrorMessage('This request has already been completed');
        break;
      case ERROR_RESPONSE_MESSAGES.REQUEST_OBSOLETE:
        setErrorMessage('This request is not valid anymore');
        break;
      case ERROR_RESPONSE_MESSAGES.REQUEST_EXPIRED:
        setErrorMessage('Reference code is expired');
        break;
      default:
        break;
    }
  };

  const authorizeReferenceCode = async (
    referenceCode: string
  ): Promise<void> => {
    try {
      setIsLoading(true);

      await axios.get(URLS.AUTHORIZE_REFERENCE_CODE, {
        headers: {
          'x-provider-reference-code': referenceCode,
        },
      });

      setReferenceCode(referenceCode);
      setPage(PAGE_TYPE.PATIENT_MEDICAL_RECORDS_REQUEST);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        decreaseAttempts(error.response?.data.message);
        handleErrorMessage(error.response?.data.message);
      } else {
        showMessage('Something went wrong. Please try again later.', 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    errorMessage,
    setErrorMessage,
    remainingAttempts: attempts,
    authorizeReferenceCode,
    isAuthorizing: isLoading,
  };
};

export default useReferenceCode;
