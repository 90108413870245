import {useCallback, useEffect, useMemo, useState} from 'react';
import axios from 'axios';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {v4 as uuidv4} from 'uuid';

import {metaDataState} from '../atoms/metaDataState';
import {pageTypeState} from '../atoms/pageTypeState';
import {referenceCodeState} from '../atoms/referenceCodeState';
import {
  COMPLETED_REQUEST_STATUSES,
  MetaDataState,
  PAGE_TYPE,
} from '../constants/types';
import {URLS} from '../constants/urls';
import {completeMedicalRecordsRequest} from '../utils/completeMedicalRecordsRequest';
import {
  FileContentType,
  getContentType,
  parseFileData,
} from '../utils/fileViewer';

import useHandleError from './useHandleError';
import useSnackbar from './useSnackbar';

export type UploadedFile = {
  id: string;
  file: File;
  contentType: FileContentType;
  hasError: boolean;
  isUploaded: boolean;
  isUploading: boolean;
  isSelected: boolean;
  gcpFileName: string;
};

export type FilePreview = {
  type: string;
  data: string;
};

const filePreviewInitialState = {
  type: '',
  data: '',
};

type FileUploadLinkResponse = {
  upload_url: string;
  file_name: string;
};

const getUploadedFileMetadata = async (
  fileName: string,
  contentType: string,
  currentSessionMetadata: MetaDataState,
  referenceCode: string
): Promise<{
  gcpFileUploadUrl: string;
  gcpFileUploadName: string;
}> => {
  const {
    data: {upload_url, file_name},
  } = await axios.post<FileUploadLinkResponse>(
    URLS.FILE_UPLOAD,
    {
      file_name: fileName,
      content_type: contentType,
      consumer_uuid: currentSessionMetadata.consumerUuid,
      client_id: currentSessionMetadata.clientId,
      relying_party_id: currentSessionMetadata.relyingPartyId,
      provider_name: currentSessionMetadata.providerName,
      uploaded_by: currentSessionMetadata.uploadedBy,
    },
    {
      headers: {
        'x-provider-reference-code': referenceCode,
      },
    }
  );

  return {
    gcpFileUploadUrl: upload_url,
    gcpFileUploadName: file_name,
  };
};

const sendFilesToTemporaryBucket = async (
  uploadUrl: string,
  uploadFile: UploadedFile
): Promise<void> => {
  await axios.put(uploadUrl, uploadFile.file, {
    headers: {
      'Content-Type': uploadFile.contentType.headerContentType,
    },
  });
};

const sendFilesToPermanentBucket = async (
  filesForSave: UploadedFile[],
  currentSessionMetadata: MetaDataState,
  referenceCode: string
): Promise<void> => {
  await axios.post(
    URLS.FILE_SAVE,
    filesForSave.map(({gcpFileName, contentType: {payloadContentType}}) => ({
      file_name: gcpFileName,
      content_type: payloadContentType,
      document_type: currentSessionMetadata.documentType,
      consumer_uuid: currentSessionMetadata.consumerUuid,
      client_id: currentSessionMetadata.clientId,
      relying_party_id: currentSessionMetadata.relyingPartyId,
      provider_name: currentSessionMetadata.providerName,
      uploaded_by: currentSessionMetadata.uploadedBy,
    })),
    {
      headers: {
        'x-provider-reference-code': referenceCode,
      },
    }
  );
};

const fetchFilePreview = async (
  gcpFileName: string,
  contentType: string,
  referenceCode: string
): Promise<Response> => {
  const fileData = await fetch(URLS.FILE_PREVIEW, {
    method: 'POST',
    body: JSON.stringify({
      file_name: gcpFileName,
      content_type: contentType,
    }),
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'x-provider-reference-code': referenceCode,
    },
  });

  return fileData;
};

export const useUploadRecords = (): {
  onUploadFiles: (files: File[]) => Promise<void>;
  onSelectFileForPreview: (data: UploadedFile) => Promise<void>;
  onRetryUpload: (data: UploadedFile) => Promise<void>;
  onDeleteFile: (id: string) => void;
  onSaveFiles: () => Promise<void>;
  uploadedFiles: UploadedFile[];
  selectedFileForPreview: UploadedFile | null;
  filePreviewIsLoading: boolean;
  filePreviewHasError: boolean;
  filePreviewData: FilePreview;
  filesIsSaving: boolean;
} => {
  const uploadFileMetadata: MetaDataState = useRecoilValue(metaDataState);
  const referenceCode = useRecoilValue(referenceCodeState);
  const setPage = useSetRecoilState(pageTypeState);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [filePreviewIsLoading, setFilePreviewIsLoading] =
    useState<boolean>(false);
  const [filePreviewHasError, setFilePreviewHasError] =
    useState<boolean>(false);
  const [filePreviewData, setFilePreviewData] = useState<FilePreview>(
    filePreviewInitialState
  );
  const [filesIsSaving, setFilesIsSaving] = useState<boolean>(false);

  const handleError = useHandleError();
  const showMessage = useSnackbar();

  const setFileIsUpload = (uploadedFile: UploadedFile, gcpFileName: string) => {
    const fileChanges = {
      isUploading: false,
      isUploaded: true,
      gcpFileName,
    };

    setUploadedFiles(prevState =>
      prevState.map(file => {
        if (uploadedFile.id === file.id) {
          return {
            ...file,
            ...fileChanges,
          };
        }

        return file;
      })
    );
  };

  const setFileHasError = (uploadedFile: UploadedFile) => {
    const fileChanges = {
      isUploading: false,
      hasError: true,
      gcpFileName: '',
    };

    setUploadedFiles(prevState =>
      prevState.map(file =>
        uploadedFile.id === file.id ? {...file, ...fileChanges} : file
      )
    );
  };

  const setFileIsUploading = (uploadedFile: UploadedFile) => {
    const fileChanges = {
      isUploading: true,
      isUploaded: false,
      hasError: false,
      gcpFileName: '',
    };

    setUploadedFiles(prevState =>
      prevState.map(file =>
        uploadedFile.id === file.id ? {...file, ...fileChanges} : file
      )
    );
  };

  const uploadFile = async (uploadedFile: UploadedFile): Promise<void> => {
    try {
      const {
        file: {name: fileName},
        contentType: {payloadContentType: fileContentType},
      } = uploadedFile;

      const {gcpFileUploadUrl, gcpFileUploadName} =
        await getUploadedFileMetadata(
          fileName,
          fileContentType,
          uploadFileMetadata,
          referenceCode
        );

      await sendFilesToTemporaryBucket(gcpFileUploadUrl, uploadedFile);

      setFileIsUpload(uploadedFile, gcpFileUploadName);
    } catch (error) {
      handleError({
        error,
        serverErrorCb: () => {
          setFileHasError(uploadedFile);
        },
      });
    }
  };

  const uploadFiles = async (files: UploadedFile[]): Promise<void> => {
    await Promise.allSettled(files.map(file => uploadFile(file)));
  };

  const onUploadFiles = async (files: File[]): Promise<void> => {
    const uploadedFiles = files.reduce<UploadedFile[]>(
      (acc, curr): UploadedFile[] => {
        const contentType = getContentType(curr.name);

        if (contentType) {
          acc.push({
            id: uuidv4(),
            file: curr,
            contentType,
            hasError: false,
            isUploading: true,
            isSelected: false,
            isUploaded: false,
            gcpFileName: '',
          });
        } else {
          showMessage(
            `File "${curr.name}" has not supported file extension.`,
            'error'
          );
        }

        return acc;
      },
      []
    );

    setUploadedFiles(prevState => [...prevState, ...uploadedFiles]);

    await uploadFiles(uploadedFiles);
  };

  const getFileForPreview = useCallback(
    async ({gcpFileName, contentType: {payloadContentType}}: UploadedFile) => {
      try {
        setFilePreviewHasError(false);
        setFilePreviewIsLoading(true);

        const fileData = await fetchFilePreview(
          gcpFileName,
          payloadContentType,
          referenceCode
        );

        setFilePreviewData({
          type: payloadContentType,
          data: await parseFileData(fileData, payloadContentType),
        });
      } catch (error) {
        handleError({
          error,
          serverErrorCb: () => {
            setFilePreviewHasError(true);
          },
        });
      } finally {
        setFilePreviewIsLoading(false);
      }
    },
    [handleError, referenceCode]
  );

  const onSelectFileForPreview = async (
    selectedFile: UploadedFile
  ): Promise<void> => {
    setUploadedFiles(prevState =>
      prevState.map(file => {
        if (file.id === selectedFile.id) {
          return {
            ...file,
            isSelected: true,
          };
        }
        return {
          ...file,
          isSelected: false,
        };
      })
    );
  };

  const onDeleteFile = (id: string) => {
    setUploadedFiles(uploadedFiles.filter(file => file.id !== id));
  };

  const onRetryUpload = async (file: UploadedFile) => {
    setFileIsUploading(file);

    await uploadFile(file);
  };

  const selectedFileForPreview = useMemo(
    () => uploadedFiles.find(file => file.isSelected) ?? null,
    [uploadedFiles]
  );

  const onSaveFiles = async () => {
    try {
      setFilesIsSaving(true);

      await sendFilesToPermanentBucket(
        uploadedFiles,
        uploadFileMetadata,
        referenceCode
      );

      await completeMedicalRecordsRequest(
        uploadFileMetadata.consumerUuid,
        uploadFileMetadata.documentType,
        uploadFileMetadata.userComment,
        COMPLETED_REQUEST_STATUSES.UPDATE_RECEIVED,
        referenceCode
      );

      showMessage('Uploaded files saved successfully', 'success');

      setPage(PAGE_TYPE.FINISH);
    } catch (error) {
      handleError({
        error,
        serverErrorCb: () => {
          showMessage('Saving files failed. Please try again.', 'error');
        },
      });
    } finally {
      setFilesIsSaving(false);
    }
  };

  useEffect(() => {
    if (selectedFileForPreview?.isUploaded) {
      getFileForPreview(selectedFileForPreview);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFileForPreview]);

  return {
    onUploadFiles,
    onSelectFileForPreview,
    onDeleteFile,
    onRetryUpload,
    onSaveFiles,
    uploadedFiles,
    selectedFileForPreview,
    filePreviewIsLoading,
    filePreviewHasError,
    filePreviewData,
    filesIsSaving,
  };
};

export default useUploadRecords;
