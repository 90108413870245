import React from 'react';
import ReactDOM from 'react-dom/client';
import {CssBaseline} from '@mui/material';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <CssBaseline />
    <App />
  </>
);

reportWebVitals();
