import React from 'react';
import styled from '@emotion/styled';
import {FormLabel as MuiInputLabel, FormLabelProps} from '@mui/material';

interface Props extends FormLabelProps {
  shrink?: boolean;
}

function Label({shrink, ...props}: Readonly<Props>): JSX.Element {
  return <MuiInputLabel {...props} />;
}

export const InputLabel = styled(Label)`
  ${props => {
    if (props.shrink) {
      return `
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      `;
    }

    return '';
  }};
`;
