import React from 'react';
import {Typography} from '@mui/material';

type Props = {
  title: string;
  subtitle?: string;
  optionalTitle?: string;
};

function BlockHeader({
  title,
  subtitle,
  optionalTitle,
}: Readonly<Props>): JSX.Element {
  return (
    <>
      <Typography component="p" variant="subtitle2" gutterBottom={!!subtitle}>
        {title}&nbsp;
        {optionalTitle && (
          <Typography component="span" variant="body5">
            {optionalTitle}
          </Typography>
        )}
      </Typography>

      {subtitle && (
        <Typography
          gutterBottom
          component="p"
          variant="body5"
          color="textSecondary"
        >
          {subtitle}
        </Typography>
      )}
    </>
  );
}

export default BlockHeader;
