import React from 'react';
import {Box, Button, Typography} from '@mui/material';
import {useRecoilState, useSetRecoilState} from 'recoil';

import {confirmLeavePageModalState} from '../../../atoms/confirmLeavePageModalState';
import {pageTypeState} from '../../../atoms/pageTypeState';
import {PAGE_TYPE} from '../../../constants/types';

import BaseModalWindow from './baseModalWindow';

const TITLE = 'Are you sure you want to leave?';
const DESCRIPTION =
  "All information that you've provided in this session will be deleted. You will have to start over by entering the reference code from the fax.";

function ConfirmLeavePageModalWindow(): JSX.Element {
  const [isOpenConfirmLeavePageModal, setIsOpenConfirmLeavePageModal] =
    useRecoilState(confirmLeavePageModalState);

  const setLoginPage = useSetRecoilState(pageTypeState);

  const handleOnConfirm = () => {
    setLoginPage(PAGE_TYPE.LOGIN);
    setIsOpenConfirmLeavePageModal(false);
  };

  const handleOnCancel = () => {
    setIsOpenConfirmLeavePageModal(false);
  };

  return (
    <BaseModalWindow
      open={isOpenConfirmLeavePageModal}
      onClose={handleOnCancel}
    >
      <>
        <Typography component="h6" variant="h6">
          {TITLE}
        </Typography>
        <Typography marginTop={2}>{DESCRIPTION}</Typography>
        <Box
          display="flex"
          marginTop={5}
          justifyContent="flex-end"
          columnGap={1.5}
        >
          <Button
            variant="outlined"
            data-testid="modal-cancel-btn"
            size="presized"
            onClick={handleOnCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            data-testid="modal-confirm-btn"
            size="presized"
            onClick={handleOnConfirm}
          >
            Leave Anyway
          </Button>
        </Box>
      </>
    </BaseModalWindow>
  );
}

export default ConfirmLeavePageModalWindow;
