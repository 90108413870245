import React from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import {makeStyles} from '@mui/styles';

import {CloseIcon} from '../../assets/icons/closeIcon';
import {UploadedFile} from '../../hooks/useUploadRecords';
import {formatBytes} from '../../utils/formatBites';

import {CustomizedCard} from './customizedCard';

type Props = {
  file: UploadedFile['file'];
  onSelect: () => void;
  onDelete: () => void;
  onRetry: () => void;
  isSelected: boolean;
  hasError: boolean;
  isUploaded: boolean;
  isLoading: boolean;
  testId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  fileItemHeaderWrapper: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(1),
    wordBreak: 'break-all',
  },
  fileSizeWrapper: {
    fontSize: '14px',
    color: theme.palette.text.secondary,
  },
  footerFileWrapper: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  loadingIndicatorWrapper: {
    color: theme.palette.info.main,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  divider: {
    color: theme.palette.divider,
  },
  retryLinkWrapper: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}));

export function FileItem({
  file,
  isSelected,
  onSelect,
  onDelete,
  onRetry,
  hasError,
  isUploaded,
  isLoading,
  testId,
}: Readonly<Props>): JSX.Element {
  const classes = useStyles();

  return (
    <CustomizedCard
      handleClick={onSelect}
      isSelected={isSelected}
      testId={testId}
    >
      <div className={classes.fileItemHeaderWrapper}>
        <Typography variant="subtitle1" className="title">
          {file.name}
        </Typography>

        <Tooltip title="Remove file">
          <IconButton
            size="small"
            onClick={e => {
              e.stopPropagation();
              onDelete();
            }}
            data-testid={`remove-file-${file.name}`}
          >
            <CloseIcon fontSize="inherit" viewBox="-3 -3 20 20" />
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.footerFileWrapper}>
        <span className={classes.fileSizeWrapper}>
          {formatBytes(file.size)}
        </span>

        {isLoading ? (
          <>
            <span className={classes.divider}>&bull;</span>
            <div
              className={classes.loadingIndicatorWrapper}
              data-testid={`loading-${file.name}`}
            >
              Loading <CircularProgress size={18} />
            </div>
          </>
        ) : null}

        {hasError ? (
          <>
            <span className={classes.divider}>&bull;</span>
            <Box component="span" fontWeight={600} color="error.main">
              Loading failed
            </Box>
            <span className={classes.divider}>&bull;</span>
            <Link
              className={classes.retryLinkWrapper}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onRetry();
              }}
              href="/#"
              underline="hover"
              data-testid={`retry-${file.name}`}
            >
              Retry
            </Link>
          </>
        ) : null}

        {isUploaded ? (
          <>
            <span className={classes.divider}>&bull;</span>
            <Box component="span" fontWeight={600} color="success.main">
              Uploaded
            </Box>
          </>
        ) : null}
      </div>
    </CustomizedCard>
  );
}
