import {useEffect, useState} from 'react';
import axios, {AxiosResponse} from 'axios';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {contactSupportModalState} from '../atoms/contactSupportModalStatr';
import {metaDataState} from '../atoms/metaDataState';
import {pageTypeState} from '../atoms/pageTypeState';
import {patientInformationState} from '../atoms/patientInformationState';
import {referenceCodeState} from '../atoms/referenceCodeState';
import {REQUEST_PATIENT_MEDICAL_RECORDS_PAGE_ATTEMPTS} from '../constants/attempts';
import {FORMAT_DATE_PATTERN} from '../constants/date';
import {
  COMPLETED_REQUEST_STATUSES,
  ERROR_RESPONSE_MESSAGES,
  FormValues,
  MetaData,
  PAGE_TYPE,
  SupportPersonInfo,
} from '../constants/types';
import {URLS} from '../constants/urls';
import {completeMedicalRecordsRequest} from '../utils/completeMedicalRecordsRequest';
import {dateFormatter, formatUTCDate} from '../utils/formatDate';
import {wrapAsyncFunction} from '../utils/wrapAsyncFunction';

import useHandleError from './useHandleError';
import useSnackbar from './useSnackbar';

type ReturnHookType = {
  attempts: number;
  requestPatientMedicalRecords: (val: FormValues) => void;
  isPatientMedicalRecordsLoading: boolean;
  isMatchedPatientInfoError: boolean;
  setIsMatchedPatientInfoError: (val: boolean) => void;
};

const usePatientMedicalRecords = (): ReturnHookType => {
  const [isPatientMedicalRecordsLoading, setIsPatientMedicalRecordsLoading] =
    useState<boolean>(false);
  const [attempts, setAttempts] = useState<number>(
    REQUEST_PATIENT_MEDICAL_RECORDS_PAGE_ATTEMPTS
  );
  const [isMatchedPatientInfoError, setIsMatchedPatientInfoError] =
    useState<boolean>(false);
  const showMessage = useSnackbar();

  const setNextPage = useSetRecoilState(pageTypeState);
  const setContactSupportModalState = useSetRecoilState(
    contactSupportModalState
  );
  const setPatientInformationState = useSetRecoilState(patientInformationState);
  const setMetaDataState = useSetRecoilState(metaDataState);
  const referenceCode = useRecoilValue(referenceCodeState);
  const handleError = useHandleError();

  const handleMatchPatientInfo = async (
    patientFirstName: string,
    patientLastName: string,
    patientDateOfBirth: string,
    storedReferenceCode: string
  ): Promise<void> => {
    await axios.post(
      URLS.MATCH_PATIENT_INFO,
      {
        first_name: patientFirstName,
        last_name: patientLastName,
        date_of_birth: dateFormatter(
          new Date(patientDateOfBirth),
          FORMAT_DATE_PATTERN.YEAR_MONTH_DAY
        ),
      },
      {
        headers: {
          'x-provider-reference-code': storedReferenceCode,
        },
      }
    );
  };

  const getMetaData = async (
    storedReferenceCode: string
  ): Promise<AxiosResponse<MetaData>> => {
    const metaDataResponse = await axios.get(URLS.GET_DATA_BY_REFERENCE_CODE, {
      headers: {
        'x-provider-reference-code': storedReferenceCode,
      },
    });

    return metaDataResponse;
  };

  const saveSupportPersonInfo = async (
    supportPersonFirstName: string,
    supportPersonLastName: string,
    supportPersonPhoneNumber: string,
    supportPersonEmail: string,
    storedReferenceCode: string
  ): Promise<AxiosResponse<SupportPersonInfo>> => {
    const saveSupportPersonInfoResponse = await axios.post(
      URLS.SAVE_SUPPORT_PERSON_INFO,
      {
        first_name: supportPersonFirstName,
        last_name: supportPersonLastName,
        phone_number: supportPersonPhoneNumber,
        email: supportPersonEmail,
      },
      {
        headers: {
          'x-provider-reference-code': storedReferenceCode,
        },
      }
    );

    return saveSupportPersonInfoResponse;
  };

  const handleNotMatchPatientInformationData = (error: unknown): void => {
    if (
      axios.isAxiosError(error) &&
      error.response?.data.message &&
      error.response?.data.message ===
        ERROR_RESPONSE_MESSAGES.INFORMATION_NOT_MATCHED
    ) {
      showMessage(
        "The patient name or date of birth does not match our records. Please enter patient information according to their driver's license from the fax you received.",
        'error'
      );
      setIsMatchedPatientInfoError(true);
      setAttempts(prevState => prevState - 1);
    } else {
      showMessage('Something went wrong. Please try again later.', 'error');
      setNextPage(PAGE_TYPE.LOGIN);
    }
  };

  const getFormattedDate = (date?: Date | null): string | null => {
    if (date) {
      return formatUTCDate(date, FORMAT_DATE_PATTERN.MONTH_DAY_YEAR_SHORT);
    }

    return null;
  };

  const requestPatientMedicalRecords = async ({
    patientFirstName,
    patientLastName,
    patientDateOfBirth,
    userFirstName,
    userLastName,
    userEmail,
    userPhoneNumber,
    userComment,
    isRequestHaveMedicalRecords,
  }: FormValues): Promise<void> => {
    try {
      setIsPatientMedicalRecordsLoading(true);

      await handleMatchPatientInfo(
        patientFirstName,
        patientLastName,
        patientDateOfBirth,
        referenceCode
      );

      const [
        {data: metaDataByReferenceCode},
        {data: saveSupportPersonInfoResponse},
      ] = await Promise.all([
        getMetaData(referenceCode),
        saveSupportPersonInfo(
          userFirstName,
          userLastName,
          userPhoneNumber,
          userEmail,
          referenceCode
        ),
      ]);

      if (isRequestHaveMedicalRecords === 'do-not-have') {
        await completeMedicalRecordsRequest(
          metaDataByReferenceCode.consumerUuid,
          metaDataByReferenceCode.requestDetails?.documentType,
          userComment,
          COMPLETED_REQUEST_STATUSES.NO_UPDATES,
          referenceCode
        );
        setNextPage(PAGE_TYPE.FINISH);
      } else {
        setMetaDataState({
          clientId: saveSupportPersonInfoResponse.provider_directory.client_id,
          relyingPartyId:
            saveSupportPersonInfoResponse.provider_directory.relying_party_id,
          providerName:
            saveSupportPersonInfoResponse.provider_directory.provider_name,
          uploadedBy: `${saveSupportPersonInfoResponse.first_name} ${saveSupportPersonInfoResponse.last_name}`,
          consumerUuid: metaDataByReferenceCode.consumerUuid,
          userComment,
          documentType: metaDataByReferenceCode.requestDetails?.documentType,
        });
        setPatientInformationState({
          patientFirstName,
          patientLastName,
          patientDateOfBirth: dateFormatter(
            new Date(patientDateOfBirth),
            FORMAT_DATE_PATTERN.MONTH_DAY_YEAR_SHORT
          ),
          recordsRequestedDateFrom: getFormattedDate(
            metaDataByReferenceCode.requestDetails.dateFrom
          ),
          recordsRequestedDateTo: getFormattedDate(
            metaDataByReferenceCode.requestDetails.dateTo
          ),
          recordsIsMaximumDateRange:
            metaDataByReferenceCode.requestDetails.isMaximumDateRange,
          requestedMedicalRecordsType:
            metaDataByReferenceCode.requestDetails?.documentType,
        });

        setNextPage(PAGE_TYPE.PATIENT_UPLOAD_MEDICAL_RECORDS);
      }
    } catch (error) {
      handleError({
        error,
        serverErrorCb: () => handleNotMatchPatientInformationData(error),
      });
    } finally {
      setIsPatientMedicalRecordsLoading(false);
    }
  };

  useEffect(() => {
    if (!attempts) {
      setContactSupportModalState(true);
    }
  }, [attempts, setContactSupportModalState]);

  return {
    attempts,
    requestPatientMedicalRecords: wrapAsyncFunction(
      requestPatientMedicalRecords
    ),
    isPatientMedicalRecordsLoading,
    isMatchedPatientInfoError,
    setIsMatchedPatientInfoError,
  };
};
export default usePatientMedicalRecords;
