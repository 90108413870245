import React, {forwardRef, useCallback} from 'react';
import ReactInputMask from 'react-input-mask';
import {Cancel} from '@mui/icons-material';
import {IconButton, InputAdornment, OutlinedInput, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';

import {CalendarIcon} from '../../../../assets/icons/calendarIcon';

type InputProps = {
  selectedValue: string;
  setValue: (date: Date | null) => void;
  onChangeDateValue: (date: string) => void;
  placeholder?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  clearInputBtn: {
    '&.MuiButtonBase-root': {
      marginRight: theme.spacing(1),
    },
  },
  adornment: {
    margin: 'auto',
  },
}));

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {setValue, placeholder, selectedValue, onChangeDateValue, ...props},
    ref
  ) => {
    const classes = useStyles();

    const onClearFiled = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        setValue(null);
      },
      [setValue]
    );

    return (
      <ReactInputMask
        {...props}
        mask="99/99/9999"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChangeDateValue(e.target.value);
        }}
      >
        <OutlinedInput
          inputProps={{
            placeholder,
            'data-testid': 'date-range-picker-input',
          }}
          endAdornment={
            <InputAdornment position="end" className={classes.adornment}>
              {selectedValue ? (
                <IconButton
                  onClick={onClearFiled}
                  size="small"
                  data-testid="picker-clear-date-btn"
                  className={classes.clearInputBtn}
                >
                  <Cancel fontSize="small" />
                </IconButton>
              ) : null}
              <CalendarIcon />
            </InputAdornment>
          }
          ref={ref}
        />
      </ReactInputMask>
    );
  }
);

Input.displayName = 'Input';

export default Input;
