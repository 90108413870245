import React from 'react';
import {Button, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';

import {InfoIcon} from '../../../assets/icons/infoIcon';
import Description from '../description';

import {ViewFileWrapper} from './viewerWrapper';

type Props = {
  title?: string;
  description?: string;
  onRetry?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  infoIconWrapper: {
    backgroundColor: theme.palette.grey['100'],
    color: theme.palette.primary.main,
    width: '56px',
    height: '56px',
    borderRadius: '50%',
    marginBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export function ViewerScreenError({
  title,
  description,
  onRetry,
}: Readonly<Props>): JSX.Element {
  const classes = useStyles();

  return (
    <ViewFileWrapper>
      <div className={classes.infoIconWrapper}>
        <InfoIcon style={{width: '30px', height: '30px'}} />
      </div>
      <Description isError title={title} description={description} />
      {onRetry ? (
        <Button
          onClick={e => {
            e.stopPropagation();
            onRetry();
          }}
          data-testid="error-screen-upload-retry-button"
          size="presizedSmall"
          variant="outlined"
        >
          Retry
        </Button>
      ) : null}
    </ViewFileWrapper>
  );
}
