import React from 'react';
import {Grow, ThemeProvider} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {SnackbarProvider} from 'notistack';
import {RecoilRoot} from 'recoil';

import {PageWrapper} from './components/shared';
import {theme} from './constants/theme';
import {PageSwitcher} from './components';

const useStyles = makeStyles({containerRoot: {paddingTop: 144}});
function HealthConnectModule(): JSX.Element {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <SnackbarProvider
          TransitionComponent={Grow}
          classes={{containerRoot: classes.containerRoot}}
        >
          <PageWrapper>
            <PageSwitcher />
          </PageWrapper>
        </SnackbarProvider>
      </RecoilRoot>
    </ThemeProvider>
  );
}
export default HealthConnectModule;
