import React, {useCallback} from 'react';
import {Close} from '@mui/icons-material';
import {Alert, AlertColor, IconButton} from '@mui/material';
import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar as useNotistackSnackbar,
} from 'notistack';

type Return = (message: string, intend?: AlertColor) => void;
const useSnackbar = (): Return => {
  const {enqueueSnackbar, closeSnackbar} = useNotistackSnackbar();

  const getOptions = useCallback(
    (severity: AlertColor, icon?: React.ReactNode): OptionsObject => ({
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },

      content: (_key: SnackbarKey, children: SnackbarMessage) => (
        <Alert
          variant="filled"
          severity={severity}
          icon={icon}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{p: 0.5}}
              onClick={() => closeSnackbar(_key)}
            >
              <Close />
            </IconButton>
          }
        >
          {children}
        </Alert>
      ),
    }),
    [closeSnackbar]
  );

  return (children: SnackbarMessage, severity: AlertColor = 'info'): void => {
    enqueueSnackbar(children, getOptions(severity));
  };
};

export default useSnackbar;
