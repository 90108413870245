import React, {useCallback} from 'react';
import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';

import {FilePreview, UploadedFile} from '../../hooks/useUploadRecords';

import {FileViewer} from './fileViewer/fileViewer';
import {ViewerScreenError} from './fileViewer/viewerScreenError';
import {ViewerScreenLoading} from './fileViewer/viewerScreenLoading';
import {ViewerScreenMessage} from './fileViewer/viewerScreenMessage';
import {FileItem} from './fileListItem';

type Props = {
  filesForPreview: UploadedFile[];
  onSelectFileForPreview: (data: UploadedFile) => void;
  selectedFileForPreview: UploadedFile | null;
  onDeleteFile: (id: string) => void;
  onRetryUpload: (data: UploadedFile) => Promise<void>;
  filePreviewIsLoading: boolean;
  filePreviewHasError: boolean;
  filePreviewData: FilePreview;
};

const useStyles = makeStyles((theme: Theme) => ({
  uploadMedicalRecordsDataViewerWrapper: {
    width: '100%',
    flexGrow: '1',
    marginTop: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(1),
    minHeight: '200px',
  },
  filesListWrapper: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
    width: '520px',
    overflowY: 'auto',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    '& > div:last-of-type': {
      marginBottom: 0,
    },
  },
  filesListOverflowWrapper: {
    flex: '1 1 auto',
    overflowY: 'auto',
    height: 0,
    padding: theme.spacing(1),
  },
  filesPreviewWrapper: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
    flexGrow: 1,
    overflow: 'hidden',
  },
}));

function UploadMedicalRecordsDataViewer({
  filesForPreview,
  onDeleteFile,
  onSelectFileForPreview,
  onRetryUpload,
  selectedFileForPreview,
  filePreviewIsLoading,
  filePreviewHasError,
  filePreviewData,
}: Readonly<Props>): JSX.Element {
  const classes = useStyles();
  const onRetry = useCallback(
    () => selectedFileForPreview && onRetryUpload(selectedFileForPreview),
    [onRetryUpload, selectedFileForPreview]
  );

  return (
    <div className={classes.uploadMedicalRecordsDataViewerWrapper}>
      <div className={classes.filesListWrapper}>
        <div className={classes.filesListOverflowWrapper}>
          {filesForPreview.map(data => (
            <FilePreviewItem
              key={data.id}
              data={data}
              selectedFileForPreview={selectedFileForPreview}
              onSelectFileForPreview={onSelectFileForPreview}
              onDeleteFile={onDeleteFile}
              onRetryUpload={onRetryUpload}
            />
          ))}
        </div>
      </div>
      <div className={classes.filesPreviewWrapper}>
        {!selectedFileForPreview ? (
          <ViewerScreenMessage
            title="Secure Viewer for Personal Health Data"
            description="Select file on the left panel to preview"
          />
        ) : null}
        {selectedFileForPreview?.isUploading ? (
          <ViewerScreenLoading message="Loading File" />
        ) : null}
        {selectedFileForPreview?.hasError ? (
          <ViewerScreenError
            title="Loading Failed"
            description="Something went wrong during file loading"
            onRetry={onRetry}
          />
        ) : null}
        {selectedFileForPreview?.isUploaded ? (
          <FileViewer
            filePreviewIsLoading={filePreviewIsLoading}
            filePreviewHasError={filePreviewHasError}
            filePreviewType={filePreviewData?.type}
            filePreviewData={filePreviewData?.data}
          />
        ) : null}
      </div>
    </div>
  );
}

interface FilePreviewItemProps {
  data: UploadedFile;
  selectedFileForPreview: UploadedFile | null;
  onSelectFileForPreview: (data: UploadedFile) => void;
  onDeleteFile: (id: string) => void;
  onRetryUpload: (data: UploadedFile) => Promise<void>;
}

function FilePreviewItem({
  data,
  selectedFileForPreview,
  onSelectFileForPreview,
  onDeleteFile,
  onRetryUpload,
}: Readonly<FilePreviewItemProps>): JSX.Element {
  const onSelect = useCallback(
    () => onSelectFileForPreview(data),
    [data, onSelectFileForPreview]
  );
  const onDelete = useCallback(
    () => onDeleteFile(data.id),
    [data.id, onDeleteFile]
  );
  const onRetry = useCallback(() => onRetryUpload(data), [data, onRetryUpload]);
  return (
    <FileItem
      key={data.id}
      file={data.file}
      hasError={data.hasError}
      isUploaded={data.isUploaded}
      isLoading={data.isUploading}
      isSelected={data.id === selectedFileForPreview?.id}
      onSelect={onSelect}
      onDelete={onDelete}
      onRetry={onRetry}
      testId={`files-list-${data.file.name}`}
    />
  );
}

export default UploadMedicalRecordsDataViewer;
