import React from 'react';
import {Button, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';

import {ChevronLeftIcon} from '../../../../assets/icons/chevronLeftIcon';
import {ChevronRightIcon} from '../../../../assets/icons/chevronRightIcon';

const useStyles = makeStyles((theme: Theme) => ({
  monthSwitchButton: {
    '&.MuiButton-root': {
      height: '36px',
      width: '36px',
      minWidth: 'unset',
      border: `1px solid ${theme.palette.grey[400]}`,
      color: theme.palette.primary.main,
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
  },
}));

type Props = {
  onClick: VoidFunction;
  disabled?: boolean;
};

export function PreviousButton({
  onClick,
  disabled,
}: Readonly<Props>): JSX.Element {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      data-testid="prev-btn"
      disabled={disabled}
      variant="outlined"
      className={classes.monthSwitchButton}
    >
      <ChevronLeftIcon />
    </Button>
  );
}

export function NextButton({onClick, disabled}: Readonly<Props>): JSX.Element {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      data-testid="prev-btn"
      disabled={disabled}
      variant="outlined"
      className={classes.monthSwitchButton}
    >
      <ChevronRightIcon />
    </Button>
  );
}
