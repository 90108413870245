import React from 'react';
import {SvgIcon, SvgIconProps} from '@mui/material';

export function CalendarIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 23 23" width="20" height="18">
      <path
        d="M11.7 16C11.05 16 10.5 15.775 10.05 15.325C9.6 14.875 9.375 14.3333 9.375 13.7C9.375 13.05 9.6 12.5 10.05 12.05C10.5 11.6 11.05 11.375 11.7 11.375C12.3333 11.375 12.875 11.6 13.325 12.05C13.775 12.5 14 13.05 14 13.7C14 14.3333 13.775 14.875 13.325 15.325C12.875 15.775 12.3333 16 11.7 16ZM2.3 19.5C1.8 19.5 1.375 19.325 1.025 18.975C0.675 18.625 0.5 18.2 0.5 17.7V4.3C0.5 3.8 0.675 3.375 1.025 3.025C1.375 2.675 1.8 2.5 2.3 2.5H3.7V0.375H5.225V2.5H12.8V0.375H14.3V2.5H15.7C16.2 2.5 16.625 2.675 16.975 3.025C17.325 3.375 17.5 3.8 17.5 4.3V17.7C17.5 18.2 17.325 18.625 16.975 18.975C16.625 19.325 16.2 19.5 15.7 19.5H2.3ZM2.3 18H15.7C15.7667 18 15.8333 17.9667 15.9 17.9C15.9667 17.8333 16 17.7667 16 17.7V8.3H2V17.7C2 17.7667 2.03333 17.8333 2.1 17.9C2.16667 17.9667 2.23333 18 2.3 18ZM2 6.8H16V4.3C16 4.23333 15.9667 4.16667 15.9 4.1C15.8333 4.03333 15.7667 4 15.7 4H2.3C2.23333 4 2.16667 4.03333 2.1 4.1C2.03333 4.16667 2 4.23333 2 4.3V6.8Z"
        fill="#323232"
      />
    </SvgIcon>
  );
}
