import React from 'react';
import {Theme, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';

import ReferenceCodeCard from './referenceCodeCard';
import {BlockContainer} from './shared';

const useStyles = makeStyles((theme: Theme) => ({
  referenceCodeCardWrapper: {
    paddingTop: theme.spacing(8),
  },
}));

function ReferenceCodeBlock(): JSX.Element {
  const classes = useStyles();

  return (
    <BlockContainer withBorder>
      <>
        <Typography component="h1" variant="h3" align="center">
          Welcome to Health Bank Connect&trade;
        </Typography>
        <div className={classes.referenceCodeCardWrapper}>
          <ReferenceCodeCard />
        </div>
      </>
    </BlockContainer>
  );
}

export default ReferenceCodeBlock;
