import React from 'react';

import Description from '../description';

import {ViewFileWrapper} from './viewerWrapper';

type Props = {
  title: string;
  description?: string;
  dataTestId?: string;
};

export function ViewerScreenMessage({
  title,
  description,
  dataTestId,
}: Readonly<Props>): JSX.Element {
  return (
    <ViewFileWrapper data-testid={dataTestId}>
      <Description title={title} description={description} />
    </ViewFileWrapper>
  );
}
