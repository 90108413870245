import styled from '@emotion/styled';

export const ViewFileWrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
`;
