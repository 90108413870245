import React from 'react';
import {makeStyles} from '@mui/styles';

import {HealthConnectLogoIcon} from '../../assets/icons/healthConnectLogoIcon';

const useStyles = makeStyles(() => ({
  logoWrapper: {
    height: '56px',
    width: 'auto',
  },
  logo: {
    height: '100%',
    width: 'auto',
  },
}));
function Logo(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.logoWrapper}>
      <HealthConnectLogoIcon className={classes.logo} />
    </div>
  );
}

export default Logo;
