import React from 'react';
import styled from '@emotion/styled';
import {FormHelperText, FormHelperTextProps} from '@mui/material';

interface Props extends FormHelperTextProps {
  shrink?: boolean;
}

export function HelperText({shrink, ...props}: Readonly<Props>): JSX.Element {
  return <FormHelperText {...props} />;
}

export const InputHelperText = styled(HelperText)`
  ${(props: Props) => {
    if (props.shrink) {
      return `
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `;
    }

    return '';
  }};
`;
