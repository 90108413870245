import React, {useCallback} from 'react';
import {useRecoilValue} from 'recoil';

import {pageTypeState} from '../atoms/pageTypeState';
import {PAGE_TYPE} from '../constants/types';
import {
  FinishPage,
  LoginPage,
  RequestMedicalRecordsPage,
  UploadRecordsPage,
} from '../pages';

import ConfirmLeavePageModalWindow from './shared/modalWindow/confirmLeavePageModalWindow';
import ContactSupportModalWindow from './shared/modalWindow/cuntactSupportModalWindow';

function PageSwitcher(): JSX.Element {
  const pageType = useRecoilValue(pageTypeState);

  const renderComponent = useCallback(() => {
    switch (pageType) {
      case PAGE_TYPE.LOGIN:
        return <LoginPage />;
      case PAGE_TYPE.PATIENT_MEDICAL_RECORDS_REQUEST:
        return <RequestMedicalRecordsPage />;
      case PAGE_TYPE.PATIENT_UPLOAD_MEDICAL_RECORDS:
        return <UploadRecordsPage />;
      case PAGE_TYPE.FINISH:
        return <FinishPage />;
      default:
        return <LoginPage />;
    }
  }, [pageType]);

  return (
    <>
      {renderComponent()}
      <ConfirmLeavePageModalWindow />
      <ContactSupportModalWindow />
    </>
  );
}

export default PageSwitcher;
