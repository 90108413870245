import {atom} from 'recoil';

import {MetaDataState} from '../constants/types';

export const metaDataState = atom<MetaDataState>({
  key: 'metaData',
  default: {
    clientId: null,
    relyingPartyId: null,
    documentType: null,
    providerName: null,
    uploadedBy: null,
    consumerUuid: null,
    userComment: null,
  },
});
