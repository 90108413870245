import React from 'react';
import {SvgIcon, SvgIconProps} from '@mui/material';

export function ChevronLeftIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M8.32996 12.0016L14.005 6.35156L15.055 7.40156L10.455 12.0016L15.055 16.6016L14.005 17.6516L8.32996 12.0016Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
