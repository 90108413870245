import React from 'react';
import {Box, FormControl, Grid} from '@mui/material';
import {useFormikContext} from 'formik';

import {
  DATE_OF_BIRTH_START_YEAR,
  DATE_PICKER_FORMATS,
  FORMAT_DATE_PATTERN,
} from '../constants/date';
import {FormValues} from '../constants/types';

import {
  BlockHeader,
  DatePicker,
  InputFormControlWithFormik,
  InputHelperText,
  InputLabel,
} from './shared';

type Props = {
  isMatchedPatientInfoError: boolean;
  setIsMatchedPatientInfoError: (val: boolean) => void;
};

function PatientInformationSubForm({
  isMatchedPatientInfoError,
  setIsMatchedPatientInfoError,
}: Readonly<Props>): JSX.Element {
  const {touched, errors, isSubmitting} = useFormikContext<FormValues>();

  const handleChangeInputValue = (): void => {
    if (isSubmitting && isMatchedPatientInfoError) {
      setIsMatchedPatientInfoError(false);
    }
  };

  return (
    <Box mb={5} data-testid="patient-information-block">
      <BlockHeader
        title="Patient Information"
        subtitle="Please enter the patient's first and last name and date of birth as it appears on their driver's license."
      />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InputFormControlWithFormik
            fullWidth
            required
            id="patientFirstName"
            name="patientFirstName"
            label="First Name"
            testIdPrefix="patient-first-name"
            error={(touched.patientFirstName && errors.patientFirstName) || ''}
            controlledError={isMatchedPatientInfoError}
            onKeyDown={handleChangeInputValue}
          />
        </Grid>
        <Grid item xs={4}>
          <InputFormControlWithFormik
            fullWidth
            required
            id="patientLastName"
            name="patientLastName"
            label="Last Name"
            testIdPrefix="patient-last-name"
            error={(touched.patientLastName && errors.patientLastName) || ''}
            controlledError={isMatchedPatientInfoError}
            onKeyDown={handleChangeInputValue}
          />
        </Grid>
        <Grid item xs>
          <FormControl
            fullWidth
            required
            error={
              Boolean(
                touched.patientDateOfBirth && errors.patientDateOfBirth
              ) || isMatchedPatientInfoError
            }
          >
            <InputLabel data-testid="picker">Date of Birth</InputLabel>
            <DatePicker
              name="patientDateOfBirth"
              placeholderText="MM/DD/YYYY"
              startYear={DATE_OF_BIRTH_START_YEAR}
              dateFormat={DATE_PICKER_FORMATS.MONTH_DAY_YEAR}
              placeholder={FORMAT_DATE_PATTERN.MONTH_DAY_YEAR}
              handleChangeInputValue={handleChangeInputValue}
            />
            <InputHelperText data-testid="date-picker" shrink>
              {touched.patientDateOfBirth && errors.patientDateOfBirth}
            </InputHelperText>
          </FormControl>

          <FormControl />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PatientInformationSubForm;
