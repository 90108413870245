import axios from 'axios';

import {
  COMPLETED_REQUEST_STATUSES,
  MEDICAL_RECORDS_TYPE,
} from '../constants/types';
import {URLS} from '../constants/urls';

export const completeMedicalRecordsRequest = async (
  consumerUuid: string | null,
  documentType: MEDICAL_RECORDS_TYPE | null,
  userComment: string | null,
  status: COMPLETED_REQUEST_STATUSES,
  storedReferenceCode: string
): Promise<void> => {
  await axios.post(
    URLS.COMPLETE_REQUEST,
    {
      consumer_uuid: consumerUuid ?? '',
      document_type: documentType ?? '',
      comment: userComment ?? '',
      status,
    },
    {
      headers: {
        'x-provider-reference-code': storedReferenceCode,
      },
    }
  );
};
