export enum ERROR_RESPONSE_MESSAGES {
  WRONG_AUTH_CODE = 'WRONG_AUTH_CODE',
  REQUEST_COMPLETED = 'REQUEST_COMPLETED',
  REQUEST_OBSOLETE = 'REQUEST_OBSOLETE',
  REQUEST_EXPIRED = 'REQUEST_EXPIRED',
  ATTEMPTS_LIMIT_REACHED = 'ATTEMPTS_LIMIT_REACHED',
  INFORMATION_NOT_MATCHED = 'Wrong patient name or date of birth',
}

export enum PAGE_TYPE {
  LOGIN = 'LOGIN',
  PATIENT_MEDICAL_RECORDS_REQUEST = 'PATIENT_MEDICAL_RECORDS_REQUEST',
  PATIENT_UPLOAD_MEDICAL_RECORDS = 'PATIENT_UPLOAD_MEDICAL_RECORDS',
  FINISH = 'FINISH',
}

export type FormValues = {
  patientFirstName: string;
  patientLastName: string;
  patientDateOfBirth: string;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  userPhoneNumber: string;
  userComment: string;
  isRequestHaveMedicalRecords: string;
};

export enum MEDICAL_RECORDS_TYPE {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  BILLING_RECORDS = 'BILLING_RECORDS',
}

export type PatientInformation = {
  patientFirstName: null | string;
  patientLastName: null | string;
  patientDateOfBirth: null | string;
  requestedMedicalRecordsType: null | MEDICAL_RECORDS_TYPE;
  recordsRequestedDateFrom: null | string;
  recordsRequestedDateTo: null | string;
  recordsIsMaximumDateRange: null | boolean;
};

export type MetaData = {
  clientId: number;
  connectionId: number | null;
  consumerUuid: string;
  id: number;
  jiraTicketId: null | string;
  medicalRecordsRequestId: null | number;
  npi: string;
  providerDirectoryLocationId: null | number;
  providerNpiType: 'ORGANIZATION' | 'PRACTITIONER';
  relyingPartyId: number;
  requestType: 'FAX_FLOW';
  status: 'REQUEST_CREATED' | 'JIRA_TICKET_CREATED' | 'REQUEST_COMPLETED';
  requestDetails: {
    documentType: MEDICAL_RECORDS_TYPE | null;
    patientComment: string | null;
    dateFrom: Date | null;
    dateTo: Date | null;
    isMaximumDateRange: boolean | null;
  };
};

export enum COMPLETED_REQUEST_STATUSES {
  UPDATE_RECEIVED = 'UPDATE_RECEIVED',
  NO_UPDATES = 'NO_UPDATES',
}

export type MetaDataState = {
  clientId: null | number;
  relyingPartyId: null | number;
  providerName: null | string;
  uploadedBy: null | string;
  consumerUuid: null | string;
  userComment: null | string;
  documentType: null | MEDICAL_RECORDS_TYPE;
};

type providerType =
  | 'INDIVIDUAL'
  | 'ORGANIZATION'
  | 'EXTERNAL_INDIVIDUAL'
  | 'EXTERNAL_ORGANIZATION';

export type SupportPersonInfo = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string | null;
  provider_directory: {
    id: number;
    provider_type: providerType;
    npi: string | null;
    client_id: number;
    relying_party_id: number;
    provider_name: string | null;
    provider_first_name: string | null;
    provider_last_name: string | null;
    relying_party_name: string | null;
  };
};
