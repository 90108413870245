import React from 'react';
import {Grid, Typography} from '@mui/material';

import {BlockContainer} from './shared';

export const HOW_TO_UPLOAD_PATIENT_MEDICAL_RECORDS_REQUIREMENTS_LIST = [
  {
    id: 1,
    text: 'Enter the reference code in the field above. This reference code can be found on the patient record request.',
  },
  {
    id: 2,
    text: 'Enter the required information on the next page.',
  },
  {
    id: 3,
    text: 'Drag the files requested to the upload screen.',
  },
  {
    id: 4,
    text: 'Click the “Save” button to send the file(s) to the patient.',
  },
];

function QuestionToUserBlock(): JSX.Element {
  return (
    <BlockContainer withBorder>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Typography component="h3" variant="h4" paragraph>
            How to upload your patient’s medical records
          </Typography>
          <Typography component="p" variant="body4" paragraph>
            To securely send your patient their medical records, follow these
            instructions:
          </Typography>
          <ul>
            {HOW_TO_UPLOAD_PATIENT_MEDICAL_RECORDS_REQUIREMENTS_LIST.map(
              ({id, text}) => (
                <li key={id}>
                  <Typography component="p" variant="body4">
                    {text}
                  </Typography>
                </li>
              )
            )}
          </ul>
        </Grid>
        <Grid item xs={6}>
          <Typography component="h3" variant="h4" paragraph>
            Don’t have medical records for the patient?
          </Typography>
          <Typography component="p" variant="body4">
            Enter the reference code from the patient record request and send a
            secure message to the patient on the next page to notify them you do
            not have records.
          </Typography>
        </Grid>
      </Grid>
    </BlockContainer>
  );
}

export default QuestionToUserBlock;
