import React, {useCallback, useMemo} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {confirmLeavePageModalState} from '../atoms/confirmLeavePageModalState';
import {patientInformationState} from '../atoms/patientInformationState';
import {
  Description,
  DropZone,
  InfoTooltip,
  UploadMedicalRecordsDataViewer,
} from '../components/shared';
import {MEDICAL_RECORDS_TYPE} from '../constants/types';
import useUploadRecords from '../hooks/useUploadRecords';

const WORDINGS = {
  IMAGES: 'Images',
  TEXT_RECORDS: 'Medical Records',
  BILLING_RECORDS: 'Billing Records',
  DEFAULT: 'Records',
};

function UploadRecordsPage(): JSX.Element {
  const {
    patientFirstName,
    patientLastName,
    patientDateOfBirth,
    requestedMedicalRecordsType,
    recordsRequestedDateFrom,
    recordsRequestedDateTo,
    recordsIsMaximumDateRange,
  } = useRecoilValue(patientInformationState);
  const setIsOpenConfirmLeavePageModal = useSetRecoilState(
    confirmLeavePageModalState
  );
  const {
    onUploadFiles,
    onSelectFileForPreview,
    onRetryUpload,
    onDeleteFile,
    onSaveFiles,
    uploadedFiles,
    filePreviewIsLoading,
    filePreviewHasError,
    filePreviewData,
    filesIsSaving,
    selectedFileForPreview,
  } = useUploadRecords();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      await onUploadFiles(acceptedFiles);
    },

    [onUploadFiles]
  );

  const onSaveUploadedFiles = useCallback(async () => {
    await onSaveFiles();
  }, [onSaveFiles]);

  const onOpenConfirmLeaveModalWindow = useCallback(() => {
    setIsOpenConfirmLeavePageModal(true);
  }, [setIsOpenConfirmLeavePageModal]);

  const canSaveFiles = useMemo(
    () =>
      uploadedFiles.length !== 0 &&
      uploadedFiles.every(file => file.isUploaded),
    [uploadedFiles]
  );

  let textForRequestedMedicalRecordsType: string;

  switch (requestedMedicalRecordsType) {
    case MEDICAL_RECORDS_TYPE.IMAGE:
      textForRequestedMedicalRecordsType = WORDINGS.IMAGES;
      break;
    case MEDICAL_RECORDS_TYPE.TEXT:
      textForRequestedMedicalRecordsType = WORDINGS.TEXT_RECORDS;
      break;
    case MEDICAL_RECORDS_TYPE.BILLING_RECORDS:
      textForRequestedMedicalRecordsType = WORDINGS.BILLING_RECORDS;
      break;
    default:
      textForRequestedMedicalRecordsType = WORDINGS.DEFAULT;
      break;
  }
  const title = `Upload ${textForRequestedMedicalRecordsType}`;
  const descriptionMessage =
    requestedMedicalRecordsType !== MEDICAL_RECORDS_TYPE.TEXT
      ? textForRequestedMedicalRecordsType.toLowerCase()
      : 'text records';
  let description = `Please upload all ${descriptionMessage} you may have for this patient`;
  if (requestedMedicalRecordsType === MEDICAL_RECORDS_TYPE.TEXT) {
    description +=
      ' which can included C-CDA documents, medical history, conditions, prescriptions, lab results, and other documents';
  } else if (requestedMedicalRecordsType === MEDICAL_RECORDS_TYPE.IMAGE) {
    description +=
      ' which can included DICOM files, ultrasounds, x-rays, MRIs, and other images';
  } else if (
    requestedMedicalRecordsType !== MEDICAL_RECORDS_TYPE.BILLING_RECORDS
  ) {
    description +=
      ' which can included C-CDA documents, DICOM files and other documents';
  }
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        component={Container}
        flexGrow={1}
        paddingTop={8}
      >
        <Description title={title} description={`${description}.`} />

        <Box
          bgcolor="background.paper"
          paddingX={3}
          paddingY={2}
          marginBottom={3}
          borderRadius={2}
        >
          <div>
            <Typography variant="h6" paragraph>
              {patientFirstName}&nbsp;
              {patientLastName}
            </Typography>

            <Box display="flex" gap={8}>
              <div>
                <Typography variant="body1" color="textSecondary">
                  Date of Birth
                </Typography>
                <Typography variant="body3">{patientDateOfBirth}</Typography>
              </div>
              {recordsRequestedDateFrom && recordsRequestedDateTo ? (
                <div>
                  <Typography variant="body1" color="textSecondary">
                    Records requested
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                  >
                    <Typography variant="body3">
                      From {recordsRequestedDateFrom} to{' '}
                      {recordsRequestedDateTo}
                    </Typography>
                    {recordsIsMaximumDateRange ? (
                      <InfoTooltip description="If you have records older than 5 years please include them." />
                    ) : null}
                  </Box>
                </div>
              ) : null}
            </Box>
          </div>
        </Box>

        <DropZone hasFiles={uploadedFiles.length !== 0} onDrop={onDrop} />

        {uploadedFiles.length !== 0 ? (
          <UploadMedicalRecordsDataViewer
            filePreviewIsLoading={filePreviewIsLoading}
            filePreviewHasError={filePreviewHasError}
            filePreviewData={filePreviewData}
            filesForPreview={uploadedFiles}
            selectedFileForPreview={selectedFileForPreview}
            onSelectFileForPreview={onSelectFileForPreview}
            onDeleteFile={onDeleteFile}
            onRetryUpload={onRetryUpload}
          />
        ) : null}
      </Box>

      <Box
        position="sticky"
        bottom={0}
        paddingY={3}
        borderTop={1}
        marginTop={1}
        borderColor="divider"
        bgcolor="common.white"
      >
        <Container>
          <Button
            size="presized"
            variant="outlined"
            onClick={onOpenConfirmLeaveModalWindow}
            data-testid="cancel-btn"
            sx={{mr: 1.5}}
          >
            Cancel
          </Button>

          <Button
            size="presized"
            variant="contained"
            data-testid="upload-btn"
            disabled={!canSaveFiles || filesIsSaving}
            onClick={onSaveUploadedFiles}
          >
            {filesIsSaving ? <CircularProgress size={18} /> : 'Save'}
          </Button>
        </Container>
      </Box>
    </>
  );
}

export default UploadRecordsPage;
