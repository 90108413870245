import React, {useEffect} from 'react';
import {Enums, init, RenderingEngine, Types} from '@cornerstonejs/core';
import * as cornerstone from '@cornerstonejs/core';
// eslint-disable-next-line
// @ts-ignore
import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import {makeStyles} from '@mui/styles';
import dicomParser from 'dicom-parser';

const useStyles = makeStyles(() => ({
  viewerWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'auto',
    backgroundColor: 'black',
  },
  viewer: {
    width: '600px',
    height: '600px',
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
  },
}));

type Props = {
  src: string;
};

cornerstoneDICOMImageLoader.external.cornerstone = cornerstone;
cornerstoneDICOMImageLoader.external.dicomParser = dicomParser;

export function ViewScreenDICOM({src}: Readonly<Props>): JSX.Element {
  const classes = useStyles();

  useEffect(() => {
    const run = async () => {
      const element = document.getElementById(
        'cornerstone-element'
      ) as HTMLDivElement;

      const renderingEngineId = 'myRenderingEngine';
      const viewportId = 'CT_STACK';
      const {ViewportType} = Enums;

      if (element) {
        await init();

        const renderingEngine = new RenderingEngine(renderingEngineId);

        const viewportInput = {
          viewportId,
          type: ViewportType.STACK,
          element,
        };

        renderingEngine.enableElement(viewportInput);

        const viewport = renderingEngine.getViewport(
          viewportId
        ) as Types.IStackViewport;

        const stack = [`wadouri:${src}`];

        await viewport.setStack(stack);

        viewport.render();
      }
    };

    run();
  }, [src]);

  return (
    <div className={classes.viewerWrapper}>
      <div className={classes.viewer} id="cornerstone-element" />
    </div>
  );
}
