import React, {useCallback, useState} from 'react';
import {makeStyles} from '@mui/styles';

type Props = {
  videoId: string;
  testId?: string;
};

const DOMAIN = 'https://player.vimeo.com/video/';

const useStyles = makeStyles(() => ({
  videoFirstPlayPlaceholder: {
    cursor: 'pointer',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: '16px',
    border: 'none',
    background: 'transparent',
  },
  video: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: '16px',
    border: 'none',
  },
}));

function VimeoVideoPlayer({testId, videoId}: Readonly<Props>): JSX.Element {
  const [isFirstPlay, setIsFirstPlay] = useState<boolean>(true);
  const classes = useStyles();

  const onPlayFist = useCallback(async () => {
    setIsFirstPlay(prevState => !prevState);
  }, []);

  const src = isFirstPlay
    ? `${DOMAIN}${videoId}?controls=0&autoplay=0&responsive=1`
    : `${DOMAIN}${videoId}?controls=1&autoplay=1&responsive=1`;

  return (
    <>
      <iframe
        data-testid={`video-${testId}`}
        className={classes.video}
        src={src}
        title="Stop faxing and join the AllClear Health Network today"
        allow="autoplay; fullscreen"
        allowFullScreen
      />

      {isFirstPlay ? (
        <button
          data-testid={`video-btn-play-${testId}`}
          aria-label="Start play"
          type="button"
          className={classes.videoFirstPlayPlaceholder}
          onClick={onPlayFist}
        />
      ) : null}
    </>
  );
}

export default VimeoVideoPlayer;
