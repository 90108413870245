import React, {useCallback} from 'react';
import {OutlinedInput, OutlinedInputProps} from '@mui/material';
import {FieldHookConfig} from 'formik/dist/Field';

import {useInputFieldFormik} from '../../../hooks';

type Props = OutlinedInputProps & FieldHookConfig<string>;
export function InputFieldWithFormik({...props}: Readonly<Props>): JSX.Element {
  const {field, helpers} = useInputFieldFormik(props);

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      field.onBlur(e);
      helpers.setTouched(true);
    },
    [field, helpers]
  );

  return <OutlinedInput {...field} {...props} onBlur={onBlur} />;
}
