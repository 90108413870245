import React from 'react';
import {Box, FormLabel, Theme, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useFormikContext} from 'formik';

import {FormValues} from '../constants/types';

import {BlockHeader, InputRadioButton} from './shared';

const useStyles = makeStyles((theme: Theme) => ({
  rootRadioButton: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
  },
}));

function RequestRecordsRadioBtnBlock(): JSX.Element {
  const classes = useStyles();
  const {values, setFieldValue} = useFormikContext<FormValues>();

  return (
    <Box mb={5} data-testid="patient-request-records">
      <BlockHeader title="Please select whether you have records for this patient" />
      <div className={classes.rootRadioButton}>
        <InputRadioButton
          name="isRequestHaveMedicalRecords"
          id="have-medical-records"
          value="have"
          inputProps={{
            'data-testid': 'have-medical-records-radio-btn',
          }}
          checked={values.isRequestHaveMedicalRecords === 'have'}
          onChange={() => {
            setFieldValue('isRequestHaveMedicalRecords', 'have');
          }}
        />
        <FormLabel htmlFor="have-medical-records">
          <Typography component="p" variant="body3" color="textPrimary">
            I have medical records for this patient
          </Typography>
        </FormLabel>
        <div />
        <Typography
          gutterBottom
          component="p"
          variant="body5"
          color="textSecondary"
        >
          The medical records you upload will be securely shared with the
          patient
        </Typography>
      </div>
      <div className={classes.rootRadioButton}>
        <InputRadioButton
          name="isRequestHaveMedicalRecords"
          id="do-not-have-medical-records"
          value="do-not-have"
          inputProps={{
            'data-testid': 'do-not-have-medical-records-radio-btn',
          }}
          checked={values.isRequestHaveMedicalRecords === 'do-not-have'}
          onChange={() => {
            setFieldValue('isRequestHaveMedicalRecords', 'do-not-have');
          }}
        />
        <FormLabel htmlFor="do-not-have-medical-records">
          <Typography component="p" variant="body3" color="textPrimary">
            I do not have medical records for this patient
          </Typography>
        </FormLabel>
        <div />
        <Typography
          gutterBottom
          component="p"
          variant="body5"
          color="textSecondary"
        >
          The patient will be notified that there are no medical records
          available at this time
        </Typography>
      </div>
    </Box>
  );
}

export default RequestRecordsRadioBtnBlock;
