import React from 'react';
import {makeStyles} from '@mui/styles';

import Header from './header';

type Props = {
  children: React.ReactElement;
};

const useStyles = makeStyles(() => ({
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  pageBody: {
    flexGrow: 1,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
}));

function PageWrapper({children}: Readonly<Props>): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.pageWrapper}>
      <Header />
      <div className={classes.pageBody}>{children}</div>
    </div>
  );
}
export default PageWrapper;
