import {atom} from 'recoil';

import {PatientInformation} from '../constants/types';

export const patientInformationState = atom<PatientInformation>({
  key: 'patientInformation',
  default: {
    patientFirstName: null,
    patientLastName: null,
    patientDateOfBirth: null,
    requestedMedicalRecordsType: null,
    recordsRequestedDateFrom: null,
    recordsRequestedDateTo: null,
    recordsIsMaximumDateRange: null,
  },
});
