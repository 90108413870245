import React from 'react';

import {StopFaxingBlock, ThankYouBlock} from '../components';

function FinishPage(): JSX.Element {
  return (
    <>
      <ThankYouBlock />
      <StopFaxingBlock />
    </>
  );
}

export default FinishPage;
