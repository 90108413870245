import React from 'react';
import {SvgIcon, SvgIconProps} from '@mui/material';

export function InfoIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} fill="none" viewBox="0 0 30 31">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15 28.5a13 13 0 1 0 0-26 13 13 0 0 0 0 26Zm0 2a15 15 0 1 0 0-30 15 15 0 0 0 0 30Zm-1-21a1 1 0 1 1 2 0v8a1 1 0 1 1-2 0v-8Zm1 11a1.2 1.2 0 1 0 0 2.4 1.2 1.2 0 0 0 0-2.4Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
}
