import React from 'react';
import {Button, Container, Theme, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';

import {useReferenceCode} from '../hooks';

import ReferenceCodeForm from './referenceCodeForm';

const LINK_CONTACT_US = 'https://support.healthbankone.com/';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '&.MuiContainer-root': {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '16px',
      boxShadow:
        '0px 8px 10px -5px rgba(0, 0, 0, 0.16), 0px 16px 24px 2px rgba(0, 0, 0, 0.1), 0px 6px 30px 5px rgba(0, 0, 0, 0.08)',
    },
  },
  formContainer: {
    '&.MuiContainer-root': {
      padding: theme.spacing(5, 8, 3, 8),
    },
  },
  attemptReachedContainer: {
    '&.MuiContainer-root': {
      padding: theme.spacing(5, 8, 5, 8),
      textAlign: 'center',
    },
  },
  referenceCodeFormWrapper: {
    padding: theme.spacing(5, 0, 5.5, 0),
  },
  attemptsReachedDescriptionWrapper: {
    padding: theme.spacing(1, 0, 5, 0),
  },
  contactSupportButton: {
    '&.MuiButton-root': {
      height: '68px',
      width: '200px',
      padding: 0,
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '24px',
    },
  },
}));

function ReferenceCodeCard(): JSX.Element {
  const {
    errorMessage,
    setErrorMessage,
    remainingAttempts,
    authorizeReferenceCode,
    isAuthorizing,
  } = useReferenceCode();
  const classes = useStyles();

  const getAttemptsReachedMessage = (remainingAttempts: number) =>
    `You have ${
      remainingAttempts === 1 ? '1 attempt' : `${remainingAttempts} attempts`
    } to enter the code`;

  return remainingAttempts === 0 ? (
    <Container
      maxWidth="md"
      className={clsx(classes.container, classes.attemptReachedContainer)}
    >
      <Typography component="h4" variant="h5" align="center">
        Attempts limit reached
      </Typography>
      <Typography
        className={classes.attemptsReachedDescriptionWrapper}
        component="p"
        variant="body4"
        align="center"
      >
        You have reached your limit of attempts to enter the reference code.
        <br />
        Please try again in 15 minutes or contact AllClear ID support.
      </Typography>
      <Button
        className={classes.contactSupportButton}
        href={LINK_CONTACT_US}
        target="_blank"
        variant="contained"
        color="primary"
      >
        Contact support
      </Button>
    </Container>
  ) : (
    <Container
      maxWidth="md"
      className={clsx(classes.container, classes.formContainer)}
    >
      <Typography component="h4" variant="h5" align="center">
        Please enter the reference code from the patient record order.
      </Typography>
      <div className={classes.referenceCodeFormWrapper}>
        <ReferenceCodeForm
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          authorizeReferenceCode={authorizeReferenceCode}
          isAuthorizing={isAuthorizing}
        />
      </div>
      <Typography align="center">
        {getAttemptsReachedMessage(remainingAttempts)}
      </Typography>
    </Container>
  );
}

export default ReferenceCodeCard;
