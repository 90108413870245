import React, {useCallback, useMemo} from 'react';
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from 'react-datepicker';
import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import dayjs from 'dayjs';
import {useField, useFormikContext} from 'formik';

import {
  DATE_PICKER_FORMATS,
  FORMAT_DATE_PATTERN,
} from '../../../constants/date';
import {
  convertYearNumberToDate,
  dateFormatter,
} from '../../../utils/formatDate';

import DatePickerInput from './components/input';
import {PickerHeader} from './components/pickerHeader';

import 'react-datepicker/dist/react-datepicker.css';

const DEFAULT_YEAR_START = 2017;

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  dateFormat: DATE_PICKER_FORMATS;
  placeholder?: string;
  handleChangeInputValue?: (value: boolean) => void;
  startYear?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  datePickerWrapper: {
    width: '100%',

    '& .react-datepicker': {
      padding: '24px',
      boxShadow: '0 4px 10px rgba(139, 139, 139, 0.25)',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      borderColor: theme.palette.grey[400],
    },

    '& .react-datepicker__header': {
      backgroundColor: theme.palette.primary.contrastText,
      borderBottom: 'none',
      padding: '0 0 8px 0',
      '& .react-datepicker__day-name': {
        color: theme.palette.grey[800],
        height: '37px',
        width: '37px',
      },
    },

    '& .react-datepicker__input-container': {
      '& .MuiInputBase-root': {
        width: 'inherit',
      },
      '& .MuiInputAdornment-root .MuiIconButton-root': {
        display: 'none',
      },
    },

    '& .react-datepicker__input-container:hover': {
      '& .MuiInputAdornment-root .MuiIconButton-root': {
        display: 'flex',
      },
    },

    '& .react-datepicker__month': {
      marginRight: 0,
      marginLeft: 0,
      '& .react-datepicker__day': {
        height: '37px',
        width: '37px',
        lineHeight: '36.5px',
      },
      '& .react-datepicker__day--selected, & .react-datepicker__day:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontWeight: 600,
        lineHeight: '36.5px',
      },
      '& .react-datepicker__day--disabled:hover, .react-datepicker__day--disabled':
        {
          backgroundColor: 'unset',
          color: theme.palette.grey[200],
          fontWeight: 'unset',
        },
    },

    '& .react-datepicker__day-names .react-datepicker__day-name:first-child, & .react-datepicker__week .react-datepicker__day:first-child':
      {
        marginLeft: 0,
      },

    '& .react-datepicker__day-names .react-datepicker__day-name:last-child, & .react-datepicker__week .react-datepicker__day:last-child':
      {
        marginRight: 0,
      },

    '& .react-datepicker__day--today, & .react-datepicker__day--keyboard-selected':
      {
        backgroundColor: theme.palette.primary.contrastText,
        fontWeight: 400,
        color: theme.palette.text.primary,
      },

    '& .react-datepicker__day--in-range, & .react-datepicker__day--today .react-datepicker__day--in-range, & .react-datepicker__day--in-selecting-range':
      {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.primary.main,
        fontWeight: 600,
      },

    '& .react-datepicker__day--selecting-range-start, & .react-datepicker__day--range-start, & .react-datepicker__day--selecting-range-end, & .react-datepicker__day--range-end':
      {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        lineHeight: '37px',
      },
  },
}));

function DatePicker({
  name,
  dateFormat,
  placeholder,
  handleChangeInputValue,
  startYear = DEFAULT_YEAR_START,
  ...props
}: Readonly<Props>): JSX.Element {
  const classes = useStyles();
  const [field] = useField<string>(name);
  const {setFieldValue, setFieldTouched} = useFormikContext();

  const minDate = convertYearNumberToDate(startYear);

  const customHeader = useCallback(
    ({
      date,
      changeYear,
      changeMonth,
      decreaseMonth,
      increaseMonth,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled,
    }: ReactDatePickerCustomHeaderProps) => (
      <PickerHeader
        date={date}
        startYear={startYear}
        handleChangeYear={changeYear}
        handleChangeMonth={changeMonth}
        handleDecreaseMonth={decreaseMonth}
        handleIncreaseMonth={increaseMonth}
        prevMonthButtonDisabled={prevMonthButtonDisabled}
        nextMonthButtonDisabled={nextMonthButtonDisabled}
      />
    ),
    [startYear]
  );

  const setDateValue = useCallback(
    (date: Date | null) => {
      setFieldValue(
        field.name,
        date ? dateFormatter(date, FORMAT_DATE_PATTERN.MONTH_DAY_YEAR) : null
      );

      if (handleChangeInputValue) {
        handleChangeInputValue(false);
      }
    },
    [handleChangeInputValue, field.name, setFieldValue]
  );

  const onChangeDateValue = useCallback(
    (date: string) => {
      setFieldValue(field.name, date);
      if (handleChangeInputValue) {
        handleChangeInputValue(false);
      }
    },
    [field.name, handleChangeInputValue, setFieldValue]
  );

  const onChangeRaw = useCallback(() => {
    setFieldTouched(name, true);
  }, [name, setFieldTouched]);

  const selectedValue = useMemo(() => {
    if (dayjs(field.value, 'MM/DD/YYYY', true).isValid()) {
      return new Date(field.value);
    }

    return null;
  }, [field.value]);

  return (
    <div className={classes.datePickerWrapper}>
      <ReactDatePicker
        {...field}
        {...props}
        renderCustomHeader={customHeader}
        maxDate={new Date()}
        minDate={minDate}
        showDisabledMonthNavigation
        fixedHeight
        showPopperArrow={false}
        selected={selectedValue}
        onChangeRaw={onChangeRaw}
        dateFormat={dateFormat}
        onChange={setDateValue}
        customInput={
          <DatePickerInput
            placeholder={placeholder}
            selectedValue={field.value}
            setValue={setDateValue}
            onChangeDateValue={onChangeDateValue}
          />
        }
      />
    </div>
  );
}
export default DatePicker;
