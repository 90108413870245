import React from 'react';
import {SvgIcon, SvgIconProps} from '@mui/material';

export function ChevronRightIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M8.94995 7.40156L10.025 6.35156L15.675 12.0016L10.025 17.6516L8.94995 16.6016L13.55 12.0016L8.94995 7.40156Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
