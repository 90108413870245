export type FileContentType = {
  payloadContentType: string;
  headerContentType: string;
};

export const FILE_WITHOUT_EXTENSION = 'BIN';

export const getContentType = (fileName: string): FileContentType | null => {
  const extensionStartIndex = fileName.lastIndexOf('.') + 1;
  const fileExtension =
    extensionStartIndex !== 0
      ? fileName.substring(extensionStartIndex, fileName.length).toUpperCase()
      : FILE_WITHOUT_EXTENSION;

  switch (fileExtension) {
    case 'CCDA':
    case 'XML':
    case 'CCD':
      return {
        payloadContentType: 'CCDA',
        headerContentType: 'application/xml',
      };
    case 'DIC':
    case 'DCM':
      return {
        payloadContentType: 'DICOM',
        headerContentType: 'application/dicom',
      };
    case 'PDF':
      return {
        payloadContentType: 'PDF',
        headerContentType: 'application/pdf',
      };
    case 'JPEG':
    case 'JPG':
      return {
        payloadContentType: 'JPEG',
        headerContentType: 'image/jpeg',
      };
    case 'PNG':
      return {
        payloadContentType: 'PNG',
        headerContentType: 'image/png',
      };
    case 'ZIP':
      return {
        payloadContentType: 'ZIP',
        headerContentType: 'application/zip',
      };
    case FILE_WITHOUT_EXTENSION:
      return {
        payloadContentType: FILE_WITHOUT_EXTENSION,
        headerContentType: 'application/octet-stream',
      };
    default:
      return null;
  }
};

export const parseFileData = async (
  response: Response,
  fileContentType: string
): Promise<string> => {
  if (fileContentType === 'CCDA') {
    const data = await response.text();

    return data;
  }

  if (fileContentType === 'JPEG') {
    const data = await response.blob();
    const newBlob = new Blob([data], {type: 'image/jpeg'});
    const imageObjectURL = URL.createObjectURL(newBlob);

    return imageObjectURL;
  }

  if (fileContentType === 'PNG') {
    const data = await response.blob();
    const newBlob = new Blob([data], {type: 'image/png'});
    const imageObjectURL = URL.createObjectURL(newBlob);

    return imageObjectURL;
  }

  if (fileContentType === 'PDF') {
    const data = await response.blob();
    const newBlob = new Blob([data], {type: 'application/pdf'});
    const pdfObjectURL = URL.createObjectURL(newBlob);

    return pdfObjectURL;
  }

  if (fileContentType === 'DICOM') {
    const data = await response.blob();
    const newBlob = new Blob([data], {type: 'application/dicom'});
    const dicomObjectURL = URL.createObjectURL(newBlob);

    return dicomObjectURL;
  }

  return '';
};
