import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import {FORMAT_DATE_PATTERN} from '../constants/date';

dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

export const convertYearNumberToDate = (year: number): Date =>
  dayjs(year.toString()).toDate();

export const formatUTCDate = (date: Date, formatter: string): string => {
  const {timeZone} = Intl.DateTimeFormat().resolvedOptions();

  return dayjs(date).utc(true).tz(timeZone).format(formatter);
};

export const dateFormatter = (
  date: Date,
  formatDatePattern: FORMAT_DATE_PATTERN
): string => dayjs(date).format(formatDatePattern);

export const dateIsBetween = (
  minData: string,
  currentDate: string,
  maxDate: string
): boolean => dayjs(currentDate).isBetween(dayjs(minData), dayjs(maxDate));

export {dayjs};
