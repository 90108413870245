import React, {ChangeEvent} from 'react';
import ReactInputMask from 'react-input-mask';
import {Box, FormControl, Grid, OutlinedInput} from '@mui/material';
import {useFormikContext} from 'formik';

import {replaceToNumbers} from '../constants/patterns';
import {FormValues} from '../constants/types';

import {
  BlockHeader,
  InputFormControlWithFormik,
  InputHelperText,
  InputLabel,
} from './shared';

function UserInformationSubForm(): JSX.Element {
  const {values, setFieldTouched, errors, setFieldValue, touched} =
    useFormikContext<FormValues>();

  const handleOnBlur = () => {
    if (values.userPhoneNumber) {
      setFieldTouched('userPhoneNumber', true);
    } else {
      setFieldTouched('userPhoneNumber', false);
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const unMaskPhoneNumber = event.target.value.replace(replaceToNumbers, '');
    setFieldValue('userPhoneNumber', unMaskPhoneNumber);
  };

  return (
    <Box mb={5} data-testid="user-information-block">
      <BlockHeader
        title="Your Information"
        subtitle="Please provide your contact information. We may need to contact you about this request."
      />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InputFormControlWithFormik
            fullWidth
            required
            id="userFirstName"
            name="userFirstName"
            label="First Name"
            testIdPrefix="user-first-name"
            error={(touched.userFirstName && errors.userFirstName) || ''}
          />
        </Grid>
        <Grid item xs={4}>
          <InputFormControlWithFormik
            fullWidth
            required
            id="userLastName"
            name="userLastName"
            label="Last Name"
            testIdPrefix="user-last-name"
            error={(touched.userLastName && errors.userLastName) || ''}
          />
        </Grid>
        <Grid item xs={4}>
          <InputFormControlWithFormik
            fullWidth
            required
            id="userEmail"
            name="userEmail"
            label="Email"
            testIdPrefix="user-email"
            error={(touched.userEmail && errors.userEmail) || ''}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl
            fullWidth
            error={Boolean(
              (errors.userPhoneNumber &&
                touched.userPhoneNumber &&
                values.userPhoneNumber.length >= 1) ||
                ''
            )}
          >
            <InputLabel
              data-testid="phone-number-label"
              htmlFor="userPhoneNumber"
            >
              Phone number (Optional)
            </InputLabel>
            <ReactInputMask
              mask="(999) 999-9999"
              id="userPhoneNumber"
              onBlur={handleOnBlur}
              onChange={handleOnChange}
            >
              <OutlinedInput
                name="userPhoneNumber"
                placeholder="(XXX) XXX-XXXX"
                inputProps={{
                  'data-testid': 'phone-input',
                }}
              />
            </ReactInputMask>
            <InputHelperText data-testid="phone-number-input-helper" shrink>
              {touched.userPhoneNumber && values.userPhoneNumber
                ? errors.userPhoneNumber
                : ''}
            </InputHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
export default UserInformationSubForm;
