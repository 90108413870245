import React from 'react';
import {Typography} from '@mui/material';

type Props = {
  title?: string;
  description?: string;
  isError?: boolean;
};

function Description({
  title,
  description,
  isError = false,
}: Readonly<Props>): JSX.Element {
  return (
    <>
      <Typography variant="h5" color={isError ? 'errorMain' : 'commonBlack'}>
        {title}
      </Typography>
      {description ? (
        <Typography
          variant="body5"
          fontStyle="italic"
          color="textSecondary"
          paragraph
        >
          {description}
        </Typography>
      ) : null}
    </>
  );
}

export default Description;
