import React from 'react';
import {Radio, RadioProps, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    display: 'inline-block',
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.grey['400']}`,
    'input:disabled ~ &': {
      opacity: 0.5,
    },
  },
  checkedIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.grey['400']}`,
    '&:before': {
      display: 'block',
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      animation: `$fadeIn ${theme.transitions.duration.standard}ms ${theme.transitions.easing.sharp}`,
      content: '""',
    },
    'input:disabled ~ &:before': {
      opacity: 0.5,
    },
  },
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}));

interface Props extends RadioProps {
  inputProps: {
    'data-testid': string;
  } & RadioProps['inputProps'];
}

export function InputRadioButton(props: Readonly<Props>): JSX.Element {
  const classes = useStyles();

  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<span className={classes.checkedIcon} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}
