import React from 'react';
import ReactInputMask from 'react-input-mask';
import {
  Button,
  CircularProgress,
  FormControl,
  OutlinedInput,
  Theme,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Form, Formik} from 'formik';
import * as yup from 'yup';

import {InputHelperText} from './shared';

type FormValues = {
  code: string;
};

const REFERENCE_CODE_LENGTH = 16;

const formInitialValues: FormValues = {
  code: '',
};

const validationSchema = yup.object({
  code: yup.string().trim().required('Reference code is required'),
});

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'grid',
    gridTemplateColumns: 'auto 200px',
    gap: `0 ${theme.spacing(2)}`,
  },
  formControl: {
    width: '100%',
  },
  input: {
    '& input': {
      backgroundColor: theme.palette.common.white,
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '32px',
      display: 'flex',
      alignItems: 'center',
      height: '68px !important',
      boxSizing: 'border-box',

      textAlign: 'center',
      '&:focus::placeholder': {
        color: 'transparent',
      },
    },
  },
  inputHelperText: {
    '&.MuiFormHelperText-root': {
      textAlign: 'center',
    },
  },
  button: {
    '&.MuiButton-root': {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '24px',
      height: '68px',
      width: '100%',
      boxShadow: 'none',
    },
  },
}));

const unmaskCode = (value: string): string =>
  value.replace(/ /g, '').replace(/_/g, '');

type Props = {
  errorMessage: string | null;
  setErrorMessage: (value: string | null) => void;
  authorizeReferenceCode: (code: string) => Promise<void>;
  isAuthorizing: boolean;
};
function ReferenceCodeForm({
  errorMessage,
  setErrorMessage,
  authorizeReferenceCode,
  isAuthorizing,
}: Readonly<Props>): JSX.Element {
  const classes = useStyles();

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={formInitialValues}
      onSubmit={async ({code}) => {
        await authorizeReferenceCode(code);
      }}
    >
      {formik => (
        <Form>
          <FormControl
            className={classes.formControl}
            error={Boolean(
              (formik.touched.code && formik.errors.code) || errorMessage || ''
            )}
          >
            <div className={classes.form}>
              <ReactInputMask
                mask="9999 9999 9999 9999"
                maskPlaceholder="_"
                id="code"
                className={classes.input}
                value={formik.values.code}
                onBlur={() => {
                  formik.setFieldTouched('code');
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue('code', unmaskCode(e.target.value));
                  setErrorMessage(null);
                }}
              >
                <OutlinedInput
                  name="code"
                  className={classes.input}
                  placeholder="Type reference code here"
                  inputProps={{
                    'data-testid': 'reference-code-input',
                  }}
                />
              </ReactInputMask>
              <div>
                <Button
                  disabled={
                    formik.values.code.length !== REFERENCE_CODE_LENGTH ||
                    !!errorMessage ||
                    isAuthorizing
                  }
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  data-testid="reference-code-submit-button"
                  type="submit"
                >
                  {isAuthorizing ? <CircularProgress size={25} /> : 'Continue'}
                </Button>
              </div>
              <div>
                <InputHelperText className={classes.inputHelperText} shrink>
                  {(formik.touched.code && formik.errors.code) ||
                    errorMessage ||
                    ''}
                </InputHelperText>
              </div>
            </div>
          </FormControl>
        </Form>
      )}
    </Formik>
  );
}

export default ReferenceCodeForm;
