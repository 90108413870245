import React, {ReactNode} from 'react';
import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';

type Props = {
  handleClick: () => void;
  isSelected?: boolean;
  children: ReactNode;
  testId?: string;
};

type StyledProps = {
  isSelected: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    /* eslint-disable indent */
    border: (props: StyledProps) =>
      props.isSelected
        ? `1px solid ${theme.palette.secondary.main}`
        : `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    borderRadius: `${theme.shape.borderRadius}px`,
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(1),

    '&:hover': {
      border: `1px solid ${theme.palette.secondary.main}`,
      backgroundColor: `${theme.palette.action.hover}`,
      cursor: 'pointer',
    },

    '&:hover .title': {
      color: theme.palette.secondary.main,
    },
  },
}));

export function CustomizedCard({
  isSelected = false,
  testId,
  handleClick,
  children,
}: Readonly<Props>): JSX.Element {
  const classes = useStyles({isSelected});

  return (
    <button
      type="button"
      tabIndex={0}
      className={classes.container}
      onKeyDown={handleClick}
      onClick={handleClick}
      data-testid={testId ?? null}
    >
      {children}
    </button>
  );
}
