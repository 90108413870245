import React from 'react';
import {Box, Button, Grid, Theme, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';

import {BlockContainer, VimeoVideoPlayer} from './shared';

const VIMEO_VIDEO_ID = '814694374';

export const STOP_FAXING_LIST = [
  {
    id: 1,
    text: 'Reduce time wasted chasing records by 90%.',
  },
  {
    id: 2,
    text: 'Access consolidated patient records instantly.',
  },
  {
    id: 3,
    text: 'Eliminate delays in care due to missing records.',
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  connectUsButton: {
    '&.MuiButton-root': {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: theme.spacing(2.25),
      lineHeight: theme.spacing(3),
      padding: theme.spacing(2),
      minHeight: theme.spacing(6.25),
    },
  },
  svgIcon: {
    '&.MuiSvgIcon-root': {
      width: '100%',
      height: theme.spacing(47),
    },
  },
  playButton: {
    overflow: 'hidden',
    borderRadius: '50%',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
  },
  videoContainer: {
    width: '100%',
    height: 0,
    paddingBottom: '56.25%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '75%',
    },
  },
  textContainer: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}));

function StopFaxingBlock(): JSX.Element {
  const classes = useStyles();

  return (
    <BlockContainer withBackground grow={1}>
      <Grid container spacing={6}>
        {/* Video Section */}
        <Grid item xs={12} sm={6}>
          <Box className={classes.videoContainer}>
            <VimeoVideoPlayer testId="finish-page" videoId={VIMEO_VIDEO_ID} />
          </Box>
          <Typography component="h5" variant="h5" align="center" marginTop={3}>
            Watch now to learn more
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={classes.textContainer}>
            <Typography component="h4" variant="h4" paragraph>
              Stop faxing and join Health Bank One™ today.
            </Typography>
            <Typography component="p" variant="body4" paragraph>
              Health Bank One is a completely new system that consolidates a
              patient’s medical records from every provider and puts them in one
              secure place, so doctors can finally access complete records at
              any time.
            </Typography>
            <ul>
              {STOP_FAXING_LIST.map(({id, text}) => (
                <li key={id}>
                  <Typography component="p" variant="body4">
                    {text}
                  </Typography>
                </li>
              ))}
            </ul>
            <Typography component="p" variant="body4">
              Contact us to learn more or request a demo.
            </Typography>
            <Box marginTop={5}>
              <Button
                className={classes.connectUsButton}
                href="mailto:support@healthbankone.com"
                data-testid="contact-us-btn"
                variant="contained"
              >
                Contact Us
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </BlockContainer>
  );
}

export default StopFaxingBlock;
