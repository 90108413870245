import {envConfig} from '../../../env';

const DATA_REQUEST_COORDINATOR_BASE_URL = `${envConfig.REACT_APP_API_URL}/healthid-data-request-coordinator/v1`;
const HEALTH_RECORDS_BASE_URL = `${envConfig.REACT_APP_API_URL}/healthid-health-records/v1`;
const HEALTH_ID_CONSUMER_API_BASE_URL = `${envConfig.REACT_APP_API_URL}/healthid-consumerapi/v1`;
const CLIENT_API_BASE_URL = `${envConfig.REACT_APP_API_URL}/healthid-clientapi/v1`;

export const URLS = {
  AUTHORIZE_REFERENCE_CODE: `${DATA_REQUEST_COORDINATOR_BASE_URL}/external/reference-code-authorization/external-requests/authorize`,
  FILE_UPLOAD: `${HEALTH_RECORDS_BASE_URL}/external/file/upload/link`,
  FILE_PREVIEW: `${HEALTH_RECORDS_BASE_URL}/external/file/preview`,
  FILE_SAVE: `${HEALTH_RECORDS_BASE_URL}/external/file/upload/save`,
  AUTHORIZE_REFERENCE_CODE_URL: `${DATA_REQUEST_COORDINATOR_BASE_URL}/external/reference-code-authorization/external-requests/authorize`,
  GET_DATA_BY_REFERENCE_CODE: `${DATA_REQUEST_COORDINATOR_BASE_URL}/external/reference-code-authorization/external-requests/data-with-details`,
  MATCH_PATIENT_INFO: `${HEALTH_ID_CONSUMER_API_BASE_URL}/consumers/reference-code-authorization/validate`,
  SAVE_SUPPORT_PERSON_INFO: `${CLIENT_API_BASE_URL}/provider-support`,
  COMPLETE_REQUEST: `${CLIENT_API_BASE_URL}/external-providers/request/complete`,
};
