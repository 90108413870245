import React from 'react';
import styled from '@emotion/styled';
import {CircularProgress} from '@mui/material';

import {ViewFileWrapper} from './viewerWrapper';

type Props = {
  message: string;
};

export function ViewerScreenLoading({message}: Readonly<Props>): JSX.Element {
  return (
    <ViewFileWrapper>
      <CircularProgress color="primary" size={60} thickness={3} />
      <LoadingDescriptionWrapper>{message}</LoadingDescriptionWrapper>
    </ViewFileWrapper>
  );
}

const LoadingDescriptionWrapper = styled.span`
  margin-top: 24px;
`;
