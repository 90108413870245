import React from 'react';

import {FaqBlock, QuestionToUserBlock, ReferenceCodeBlock} from '../components';

function LoginPage(): JSX.Element {
  return (
    <>
      <ReferenceCodeBlock />
      <QuestionToUserBlock />
      <FaqBlock />
    </>
  );
}

export default LoginPage;
