import React from 'react';
import {AppBar, Theme} from '@mui/material';
import {withStyles} from '@mui/styles';

import Logo from './logo';

const AppBarStyled = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4, 8),
  },
}))(AppBar);

function Header(): JSX.Element {
  return (
    <AppBarStyled elevation={0} position="relative">
      <Logo />
    </AppBarStyled>
  );
}
export default Header;
