import React, {ReactNode} from 'react';
import {ExpandMore} from '@mui/icons-material';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Theme,
} from '@mui/material';
import {makeStyles} from '@mui/styles';

type Props = {
  content: {id: number; title: ReactNode; body: ReactNode}[];
};

const useStyles = makeStyles((theme: Theme) => ({
  accordionItem: {
    '&.MuiAccordion-root': {
      borderTop: `1px solid ${theme.palette.divider}`,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      borderRadius: 0,
      '&:first-of-type': {
        borderRadius: 0,
      },
      '&:last-of-type': {
        borderRadius: 0,
      },
      '&.Mui-expanded': {
        margin: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&:last-child': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  accordionSummary: {
    '&.MuiAccordionSummary-root': {
      backgroundColor: 'transparent',
      minHeight: '60px',
      height: '60px',
      paddingLeft: 0,
      '&.Mui-expanded': {
        height: '60px',
        minHeight: '60px',
      },
    },
  },
  accordionDetails: {
    '&.MuiAccordionDetails-root': {
      display: 'block',
      paddingLeft: 0,
      paddingRight: theme.spacing(6),
    },
  },
}));

function Accordion({content}: Readonly<Props>): JSX.Element {
  const classes = useStyles();

  return (
    <>
      {content.map(({id, title, body}) => (
        <MuiAccordion key={id} className={classes.accordionItem}>
          <MuiAccordionSummary
            aria-controls={`panel1d-content-${id}`}
            id={`panel1d-header-${id}`}
            expandIcon={<ExpandMore />}
            className={classes.accordionSummary}
          >
            {title}
          </MuiAccordionSummary>
          <MuiAccordionDetails className={classes.accordionDetails}>
            {body}
          </MuiAccordionDetails>
        </MuiAccordion>
      ))}
    </>
  );
}

export default Accordion;
