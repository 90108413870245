import axios from 'axios';
import {useSetRecoilState} from 'recoil';

import {pageTypeState} from '../atoms/pageTypeState';
import {ERROR_RESPONSE_MESSAGES, PAGE_TYPE} from '../constants/types';

import useSnackbar from './useSnackbar';

const useHandleError = (): (({
  error,
  serverErrorCb,
  userErrorCb,
}: {
  error: unknown;
  serverErrorCb?: () => void;
  userErrorCb?: () => void;
}) => void) => {
  const setPage = useSetRecoilState(pageTypeState);
  const showMessage = useSnackbar();

  const handleServerError = (serverErrorCb?: () => void) => {
    if (serverErrorCb) {
      serverErrorCb();
    } else {
      showMessage('Something went wrong. Please try again later.', 'error');
      setPage(PAGE_TYPE.LOGIN);
    }
  };

  const handleUserError = (userErrorCb?: () => void) => {
    if (userErrorCb) {
      userErrorCb();
    } else {
      showMessage('Something went wrong. Please try again later.', 'error');
    }
  };
  const handleError = ({
    error,
    serverErrorCb,
    userErrorCb,
  }: {
    error: unknown;
    serverErrorCb?: () => void;
    userErrorCb?: () => void;
  }) => {
    if (
      axios.isAxiosError(error) &&
      (error.response?.data.message ===
        ERROR_RESPONSE_MESSAGES.WRONG_AUTH_CODE ||
        // error.message === ERROR_RESPONSE_MESSAGES.REQUEST_COMPLETED ||
        // error.message === ERROR_RESPONSE_MESSAGES.REQUEST_OBSOLETE ||
        // error.message === ERROR_RESPONSE_MESSAGES.REQUEST_EXPIRED ||
        error.response?.data.message ===
          ERROR_RESPONSE_MESSAGES.ATTEMPTS_LIMIT_REACHED)
    ) {
      showMessage('This request is not valid anymore.', 'error');
      setPage(PAGE_TYPE.LOGIN);
    } else if (axios.isAxiosError(error)) {
      handleServerError(serverErrorCb);
    } else {
      handleUserError(userErrorCb);
    }
  };

  return handleError;
};

export default useHandleError;
