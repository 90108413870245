import React from 'react';
import {SvgIcon, SvgIconProps} from '@mui/material';

export function ChevronUpDownIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.01172 15.995 9.00669 15.005 9.99664 12 6.99162 8.99495 9.99664 8.005 9.00669 12 5.01172ZM8.005 14.9939 8.99495 14.0039 12 17.0089 15.005 14.0039 15.995 14.9939 12 18.9888 8.005 14.9939Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
