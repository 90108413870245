import React, {useCallback} from 'react';
import {Box, Button, Container, Theme, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {pageTypeState} from '../atoms/pageTypeState';
import {referenceCodeState} from '../atoms/referenceCodeState';
import {PAGE_TYPE} from '../constants/types';
import {stringToReferenceCodeFormat} from '../utils/formatReferenceCode';

import {BlockContainer} from './shared';

const useStyles = makeStyles((theme: Theme) => ({
  codeField: {
    color: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 2),
    width: '100%',
  },
  processRequestButton: {
    '&.MuiButton-root': {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: theme.spacing(2.25),
      lineHeight: theme.spacing(3),
      padding: theme.spacing(2),
      height: theme.spacing(7),
    },
  },
}));

function ThankYouBlock(): JSX.Element {
  const referenceCode = useRecoilValue(referenceCodeState);
  const setPage = useSetRecoilState(pageTypeState);
  const classes = useStyles();

  const redirectToLoginPage = useCallback(() => {
    setPage(PAGE_TYPE.LOGIN);
  }, [setPage]);

  return (
    <BlockContainer>
      <Container maxWidth="md">
        <Typography component="h3" variant="h3" align="center" marginBottom={3}>
          Thank you for your response.
        </Typography>
        <Typography component="p" variant="body4" align="center">
          Your patient will be notified that you have fulfilled their request.
        </Typography>
        <Typography
          component="p"
          variant="body4"
          align="center"
          marginBottom={3}
        >
          The reference code for this transaction is:
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          paddingLeft={12}
          paddingRight={12}
        >
          <Typography
            component="span"
            variant="h4"
            textAlign="center"
            className={classes.codeField}
            marginBottom={3}
          >
            {stringToReferenceCodeFormat(referenceCode)}
          </Typography>

          <Button
            onClick={redirectToLoginPage}
            variant="outlined"
            className={classes.processRequestButton}
            color="primary"
            size="large"
            data-testid="new-request-btn"
          >
            Process Next Request
          </Button>
        </Box>
      </Container>
    </BlockContainer>
  );
}

export default ThankYouBlock;
