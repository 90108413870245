export enum FORMAT_DATE_PATTERN {
  YEAR_MONTH_DAY = 'YYYY-MM-DD',
  MONTH_DAY_YEAR = 'MM/DD/YYYY',
  MONTH_DAY_YEAR_SHORT = 'M/D/YYYY',
}

export enum DATE_PICKER_FORMATS {
  MONTH_DAY_YEAR = 'MM/dd/yyyy',
}

export const DATE_OF_BIRTH_START_YEAR = 1900;
