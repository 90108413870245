import React from 'react';
import {createTheme} from '@mui/material';

// eslint-disable-next-line import/no-mutable-exports
let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
    primary: {
      light: '#3376B8',
      main: '#0054A6',
      dark: '#003C76',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#699BDA',
      main: '#4382D1',
      dark: '#305C94',
      contrastText: '#FFFFFF',
    },
    error: {
      light: '#E53935',
      main: '#D32F2F',
      dark: '#B71C1C',
      contrastText: '#FFFFFF',
    },
    warning: {
      light: '#FFEE58',
      main: '#FFEB3B',
      dark: '#FBC02D',
      contrastText: '#000000',
    },
    info: {
      light: '#699BDA',
      main: '#4382D1',
      dark: '#305C94',
      contrastText: '#FFFFFF',
    },
    success: {
      light: '#388E3C',
      main: '#2E7D32',
      dark: '#1B5E20',
      contrastText: '#FFFFFF',
    },
    grey: {
      50: '#FAFBFB',
      100: '#F5F6F6',
      200: '#F0F1F1',
      300: '#E3E4E4',
      400: '#C0C1C1',
      500: '#A2A3A3',
      600: '#797A7A',
      700: '#646565',
      800: '#454646',
      900: '#242525',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      // hint: 'rgba(0, 0, 0, 0.38)',
    },
    background: {
      default: '#FFFFFF',
      paper: '#F5F6F6',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      selected: 'rgba(0, 0, 0, 0.08)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      focus: 'rgba(0, 0, 0, 0.12)',
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: '"Open Sans", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontWeight: 600,
      fontSize: '81px',
      lineHeight: '112px',
      letterSpacing: '-2.5px',
    },
    h2: {
      fontWeight: 600,
      fontSize: '60px',
      lineHeight: '84px',
      letterSpacing: '-1px',
    },
    h3: {
      fontWeight: 600,
      fontSize: '48px',
      lineHeight: '68px',
      letterSpacing: 0,
    },
    h4: {
      fontWeight: 600,
      fontSize: '28px',
      lineHeight: '40px',
      letterSpacing: 0,
    },
    h5: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '36px',
      letterSpacing: 0,
    },
    h6: {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '28px',
      letterSpacing: 0,
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.01px',
    },
    body1: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
    body2: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0',
    },
    body3: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
    body4: {
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '28px',
      letterSpacing: '0',
    },
    body5: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      fontStyle: 'italic',
    },
    button: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: 0,
    },
    caption: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
    },
    hint: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      fontStyle: 'italic',
    },
    overline: {
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
      fontStyle: 'normal',
    },
  },
  shape: {
    borderRadius: 4,
  },
});

theme = createTheme(theme, {
  components: {
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          position: 'relative',
          transform: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& input': {
            height: '48px',
            boxSizing: 'border-box',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          lineHeight: '24px',
          textAlign: 'left',
          color: theme.palette.text.secondary,
          '&.Mui-disabled': {
            color: theme.palette.text.secondary,
          },
        },
        asterisk: {
          color: theme.palette.error.main,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          fontSize: '12px',
          lineHeight: '16px',
          fontWeight: 400,
          fontStyle: 'italic',
          color: theme.palette.grey['500'],
          '&:empty::before': {
            content: '""',
            display: 'inline-block',
          },
          '&.Mui-disabled': {
            color: theme.palette.grey['900'],
          },
        },
        contained: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          alignItems: 'stretch',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          height: 'auto',
          maxHeight: 'auto',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: {size: 'presized'},
          style: {
            minWidth: 110,
            minHeight: 44,
          },
        },
        {
          props: {size: 'presizedSmall'},
          style: {
            maxHeight: 36,
            fontWeight: 600,
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          fontSize: theme.typography.fontSize,
          boxShadow: 'none',
          textTransform: 'none',
          lineHeight: '20px',
          fontWeight: 600,

          '&:hover': {
            boxShadow: '0px 4px 10px rgba(36, 71, 130, 0.16)',
          },
        },
        outlined: {
          fontSize: theme.typography.fontSize,
          boxShadow: 'none',
          textTransform: 'none',
          lineHeight: '20px',
          fontWeight: 600,

          '&:hover': {
            boxShadow: '0px 4px 10px rgba(36, 71, 130, 0.16)',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
        message: {
          fontWeight: '700',
          fontSize: '14px',
          lineHeight: '24px',
          maxWidth: '400px',
        },
      },
    },
  },
});

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    body5: React.CSSProperties;
    hint: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    body5?: React.CSSProperties;
    hint: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
    body5: true;
    hint: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    presized: true;
    presizedSmall: true;
  }
}

export {theme};
