import React from 'react';
import {FormControl} from '@mui/material';

import {InputFieldWithFormik} from './InputFieldWithFormik';
import {InputHelperText} from './InputHelperText';
import {InputLabel} from './InputLabel';

type Props = {
  id: string;
  name: string;
  label: string;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  description?: boolean;
  required?: boolean;
  shrink?: boolean;
  testIdPrefix?: string;
  fullWidth?: boolean;
  controlledError?: boolean;
  onKeyDown?: () => void;
};

export function InputFormControlWithFormik({
  id,
  error,
  name,
  label,
  disabled,
  required,
  placeholder,
  description,
  shrink = true,
  testIdPrefix = 'default',
  fullWidth = false,
  controlledError,
  onKeyDown,
}: Readonly<Props>): JSX.Element {
  return (
    <FormControl
      disabled={disabled}
      required={required}
      error={Boolean(error) || controlledError}
      fullWidth={fullWidth}
    >
      <InputLabel data-testid={`${testIdPrefix}-label`} htmlFor={id}>
        {label}
      </InputLabel>
      <InputFieldWithFormik
        id={id}
        name={name}
        placeholder={placeholder}
        inputProps={{
          'data-testid': `${testIdPrefix}-input`,
        }}
        onKeyDown={onKeyDown}
      />
      <InputHelperText
        data-testid={`${testIdPrefix}-input-helper`}
        shrink={shrink}
      >
        {error ?? description}
      </InputHelperText>
    </FormControl>
  );
}
