import React, {useCallback, useState} from 'react';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  ClickAwayListener,
  Fade,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Theme,
} from '@mui/material';
import {makeStyles} from '@mui/styles';

import {ChevronUpDownIcon} from '../../../../assets/icons/chevronUpDownIcon';
import {DateListType} from '../constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  dateSelectButton: {
    '&.MuiButton-root': {
      height: '36px',
      width: '100%',
      border: `1px solid ${theme.palette.grey[400]}`,
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '5px',
    },
  },
}));

type Props = {
  selectedValue: string;
  handleChange: (val: number) => void;
  items: DateListType;
};

export function DateSelect({
  selectedValue,
  items,
  handleChange,
}: Readonly<Props>): JSX.Element {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const handleClickAway = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onClick = useCallback(() => setIsOpen(prevState => !prevState), []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box style={{width: 'inherit'}}>
        <Button
          endIcon={<ChevronUpDownIcon />}
          onClick={onClick}
          variant="outlined"
          className={classes.dateSelectButton}
        >
          {selectedValue}
        </Button>
        <Fade in={isOpen}>
          <MenuListContainer>
            <Paper>
              <StyledList>
                {Object.keys(items).map((item: string) => (
                  <ListItemFun
                    key={item}
                    item={item}
                    items={items}
                    selectedValue={selectedValue}
                    setIsOpen={setIsOpen}
                    handleChange={handleChange}
                  />
                ))}
              </StyledList>
            </Paper>
          </MenuListContainer>
        </Fade>
      </Box>
    </ClickAwayListener>
  );
}

const MenuListContainer = styled.div`
  position: absolute;
  width: inherit;
`;

const StyledListItem = styled(ListItemButton)`
  padding-bottom: 0;
  padding-top: 0;
`;

const StyledList = styled(List)`
  max-height: 350px;
  overflow-y: auto;
`;

interface ListItemFunProps {
  item: string;
  items: DateListType;
  selectedValue: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleChange: (val: number) => void;
}

function ListItemFun({
  item,
  items,
  selectedValue,
  setIsOpen,
  handleChange,
}: Readonly<ListItemFunProps>): JSX.Element {
  const onClick = useCallback(() => {
    setIsOpen(false);
    handleChange(Number(item));
  }, [handleChange, item, setIsOpen]);

  return (
    <StyledListItem
      key={item}
      selected={items[Number(item)] === selectedValue}
      onClick={onClick}
    >
      <ListItemText primary={items[Number(item)]} />
    </StyledListItem>
  );
}
