import React, {ChangeEvent} from 'react';
import {Box, FormControl, OutlinedInput, Typography} from '@mui/material';
import {useFormikContext} from 'formik';

import {FormValues} from '../constants/types';

import {BlockHeader} from './shared';

const MAX_NUMBERS_OF_CHARACTERS = 500;

function UserCommentSubForm(): JSX.Element {
  const {values, setFieldValue} = useFormikContext<FormValues>();

  return (
    <Box mb={5} data-testid="user-comment-block">
      <BlockHeader
        title="Comment"
        subtitle="Please provide any related comments to our support team. It may help us to solve the issues with the patient's request."
        optionalTitle=" (Optional)"
      />
      <FormControl fullWidth>
        <OutlinedInput
          multiline
          rows={4}
          name="userComment"
          id="userComment"
          placeholder="Type your comment here"
          inputProps={{
            maxLength: MAX_NUMBERS_OF_CHARACTERS,
            'data-testid': 'user-comment-text-area',
          }}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setFieldValue('userComment', event.target.value)
          }
        />
      </FormControl>
      <Typography
        component="p"
        variant="body5"
        color="textSecondary"
        align="right"
      >{`${values.userComment.length}/${MAX_NUMBERS_OF_CHARACTERS}`}</Typography>
    </Box>
  );
}

export default UserCommentSubForm;
