import React from 'react';
import {SvgIcon, SvgIconProps} from '@mui/material';

export function CloseIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 15 15" width="15" height="15" {...props}>
      <path d="M1.39998 13.65L0.349976 12.6L5.94998 6.99998L0.349976 1.39998L1.39998 0.349976L6.99998 5.94998L12.6 0.349976L13.65 1.39998L8.04998 6.99998L13.65 12.6L12.6 13.65L6.99998 8.04998L1.39998 13.65Z" />
    </SvgIcon>
  );
}
