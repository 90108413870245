import React from 'react';
import {Box, Container, useTheme} from '@mui/material';

type Props = {
  children: React.ReactElement;
  withBorder?: boolean;
  withBackground?: boolean;
  grow?: number;
};

function BlockContainer(props: Readonly<Props>): JSX.Element {
  const theme = useTheme();
  const {children} = props;

  return (
    <Box
      component="div"
      flexGrow={props?.grow ?? 0}
      paddingY={8}
      paddingX={3}
      borderBottom={
        props?.withBorder ? `1px solid ${theme.palette.divider}` : ''
      }
      bgcolor={
        props?.withBackground ? theme.palette.background.paper : 'transparent'
      }
    >
      <Container disableGutters>{children}</Container>
    </Box>
  );
}

export default BlockContainer;
